<template>
    <span>
        <on-app-load :isEnabled.sync="isEnabled" />
        <wait-for v-if="isEnabled" target="body" :set-for="50" once>
            <on-app-enabled :currentPageType.sync="currentPageType" />
            <div v-if="isEnabled && currentPageType" id="main-div">
                <checkout v-if="currentPageType.isCheckout"></checkout>
                <account-page></account-page>
            </div>
            <product-widget :enabled="isEnabled" />
        </wait-for>
    </span>
</template>

<script>
import { OnAppLoad, OnAppEnabled } from "@/bigcommerce/pages";
import { appSupportedPageTypes } from "@/bigcommerce/vue/plugins";
import WaitFor from "@/core/vue/components/WaitFor";
import ProductWidget from "@/bigcommerce/pages/shared/productWidget/ProductWidget";

export default {
    name: "App",
    components: {
        Checkout: () =>
            import(/* webpackChunkName: "checkout" */ "@/bigcommerce/pages/checkout/Checkout.vue"),
        WaitFor,
        AccountPage: () =>
            import(/* webpackChunkName: "account" */ "@/bigcommerce/pages/account/AccountPage.vue"),
        OnAppLoad,
        OnAppEnabled,
        ProductWidget,
    },
    /**
     * Initializing the variables for the application.
     * @returns {object} All the data initialized.
     */
    data() {
        return {
            /**
             * An object of page types.
             */
            supportedPageTypes: appSupportedPageTypes,
            /**
             * @type {object} If true, the app is ready to mount.
             */
            currentPageType: null,
            /**
             * @type {boolean} If true, the app is enabled for this store.
             */
            isEnabled: false,
        };
    },
};
</script>
<style>
.rca-hide-element {
    display: None !important;
}
</style>
