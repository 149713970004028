import { render, staticRenderFns } from "./OnAppEnabled.vue?vue&type=template&id=06a91b28&"
import script from "./OnAppEnabled.vue?vue&type=script&lang=js&"
export * from "./OnAppEnabled.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.6_@vue+compiler-sfc@3.4.27_babel-core@6.26.3_css-loader@6.0.0_webpack@5.24.4__4cjfcwovuspyvxkzbh35sbxuem/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports