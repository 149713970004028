/* eslint-disable jsdoc/check-property-names */
import { throttle } from "lodash";
import store from "@/core/vue/store";
import { Logger } from "@/core/utils/logger";

const logger = new Logger("GetUpdatedCartObserver");

const config = {
    // Keyboard keys that should trigger the event handler.
    keysToMonitor: ["Enter", "Space"],
    // Potential delimiters for target string.
    delims: ["", ".", "-", "_", " "],
    // When True, the next valid event should trigger a getCart refresh.
    waitForNextEvent: false,
};

/**
 * Returns the HTML of the element's DOM tree. For example,
 * "<body>
 *     <div>
 *         <span><p>Hello</p></span>
 *     </div>
 *     <div>
 *         <span><p>World</p></span>
 *     </div>
 * </body>",
 * would return "<body><div><span></span></div></body>" when targeting the first span.
 *
 * @param {HTMLElement} el The target element.
 * @returns {string} The HTML of the element's DOM tree.
 */
const getTreeHtml = (el) => {
    let val = el.outerHTML.replace(el.innerHTML, "");
    while (el.parentElement && el.tagName.toLowerCase() !== "body") {
        el = el.parentElement;
        val = el.outerHTML.replace(el.innerHTML, val);
    }
    return val;
};

const checkEvent = {
    /**
     * @param {EventTarget} target The event target.
     * @returns {boolean} Returns True if the target is within the DOM tree of a "Remove From Cart" element.
     */
    isRemoveFromCartEvent: (target) => {
        const parts = ["cart", "remove"];
        const treeHtml = getTreeHtml(target).toLowerCase();
        return config.delims.some((d) => treeHtml.includes(parts.join(d)));
    },
    /**
     * @param {EventTarget} target The event target.
     * @returns {boolean} Returns True if the target is within the DOM tree of a "Edit Cart" element.
     */
    isEditCartAction: (target) => {
        const actions = [
            ["add", "to", "cart"],
            ["cart", "item"],
        ];
        const treeHtml = getTreeHtml(target).toLowerCase();

        return actions.some((parts) => config.delims.some((d) => treeHtml.includes(parts.join(d))));
    },
};

/**
 * @returns {boolean} Returns True if the current cart data in localstorage is older than maxCartAge.
 */
const isCartDataRecent = () => {
    const maxCartAge = 2000; // ms
    const cart = store.getters["cart/getCart"];
    const timeSinceLastUpdate = Date.now() - Date.parse(cart.updatedTime);
    return timeSinceLastUpdate < maxCartAge;
};

/**
 *
 * @param {Function} getCart Function to get the cart from BigCommerce.
 * @param {Function} isCheckout Function that returns True if the current page is a BigCommerce Checkout page.
 * @param {MouseEvent|KeyboardEvent} event The event fired.
 */
function handler(getCart, isCheckout, event) {
    const isValidEvent = event.code ? config.keysToMonitor.includes(event.code) : true;
    const getCartAndCheck = () => {
        // This gets the cart from the storefront api. We often get race conditions here, so we also
        // perform a check to see if we need to try again. This means invalid events get the cart
        // twice, but valid events only trigger once. This on a "human-scale", though, so the benefit
        // is worth the performance overhead.
        getCart();
        if (!isCartDataRecent()) {
            setTimeout(getCart, 500);
        }
    };
    if (!isCheckout() && isValidEvent) {
        logger.meta("Get Updated Cart Handler Fired", { getCart, isCheckout, event });
        if (checkEvent.isRemoveFromCartEvent(event.target)) {
            // When a "Remove from cart event" is clicked, a confirmation modal is often show. We
            // get the cart now, but also get ready to re-get the cart when the confirmation button
            // is selected.
            getCartAndCheck();
            config.waitForNextEvent = true;
        } else if (config.waitForNextEvent && event.target.tagName.toLowerCase() === "button") {
            // We get here when a "Remove from cart" confirmation button is selected.
            config.waitForNextEvent = false;
            getCartAndCheck();
        } else if (checkEvent.isEditCartAction(event.target)) {
            // This should be hit when normal Add to cart or edit cart actions are performed.
            getCartAndCheck();
        }
    }
}

/**
 * Adds body level event listeners to trigger a BigCommerce cart refresh.
 *
 * @param {object} root Root object.
 * @param {Function} root.getUpdatedCart  Gets an updated cart from BigCommerce.
 * @param {Function} root.isCheckout Returns True if the current page is a Checkout page.
 */
export function getUpdatedCartListener({ getUpdatedCart, isCheckout }) {
    if (!config.listenersAdded) {
        logger.meta("Starting Cart Listener");
        const getCart = throttle(getUpdatedCart, 200, {
            leading: false,
            trailing: true,
        });

        document.body.addEventListener("keyup", handler.bind(null, getCart, isCheckout), {
            capture: true,
            passive: true,
        });
        document.body.addEventListener("click", handler.bind(null, getCart, isCheckout), {
            capture: true,
            passive: true,
        });
        config.listenersAdded = true;
    }
}
