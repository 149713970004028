<template>
    <div class="rca-subscription-widget" :style="cssProps" v-if="product.id">
        <prepaid-subscription
            v-if="product.isPrepaid"
            :show-main-title="true"
            :subscription="product.subscription"
            :product="product"
        />
        <button-form v-else-if="subscriptionFormType === 'button'" :product="product"></button-form>
        <radio-form v-else-if="subscriptionFormType === 'radio'" :product="product" />
        <checkbox-form v-else-if="subscriptionFormType === 'checkbox'" :product="product" />
        <subscription-form-r-c-info v-if="showRCInfo" :show-popup="showRCInfo" />
    </div>
</template>

<script>
import ButtonForm from "@/core/ProductWidget/FormTypes/ButtonFormType/ButtonForm";
import SubscriptionFormRCInfo from "@/core/vue/components/SubscriptionForm/SubscriptionFormRCInfo.vue";
import RadioForm from "./FormTypes/RadioFormType/RadioForm.vue";
import { adapterBackendMixin } from "@/core/utils";
import PrepaidSubscription from "@/core/vue/components/SubscriptionForm/PrepaidSubscription";
import CheckboxForm from "./FormTypes/CheckboxFormType/CheckboxForm.vue";

// noinspection JSValidateTypes
export default {
    name: "ProductWidget",
    mixins: [adapterBackendMixin],
    components: {
        ButtonForm,
        SubscriptionFormRCInfo,
        RadioForm,
        CheckboxForm,
        PrepaidSubscription,
    },
    /**
     * @returns {object} Information for the product subscription and widget configuration.
     */
    data() {
        return {
            productId: this.product.id,
            subscriptionModId: this.$store_data?.getProductByBCProductID(this.productId)
                ?.subscriptionModifier?.id,
        };
    },
    props: {
        /**
         * @type {RcaDataProduct}
         */
        product: Object,
    },
    computed: {
        /**
         * Sets the styling props that the widget is going to use.
         * @returns {object} The CSS props from the settings.
         */
        cssProps() {
            const styles = this.settings?.style || {};
            const css = {
                "--widgetCursor": this.showPopup ? "pointer" : "auto",
            };

            Object.entries(styles).forEach(([style, value]) => (css[`--${style}`] = value));

            return css;
        },
        /**
         * @returns {string} The type of ReCharge Subscription form to display.
         */
        subscriptionFormType() {
            return this.settings?.pages?.product?.form_type ?? "button";
        },
        /**
         * @returns {boolean} If True, display the ReCharge Subscription Tooltip.
         */
        showRCInfo() {
            return this.settings?.pages?.product?.tooltip_enabled;
        },
    },
};
</script>

<style>
.rca-subscription-widget {
    padding-bottom: 1.5em;
    font-size: 1rem;
}
</style>
