<!-- This component will move the slotted component to another location within the DOM. -->
<template>
    <MountingPortal :key="mountKey" v-if="mountReady && !abort" :mount-to="mountTo" target-slim>
        <slot></slot>
    </MountingPortal>
</template>

<script>
import { nanoid } from "nanoid";
import jQuery from "jquery-slim";
import { merge } from "lodash";

export default {
    name: "Teleport",
    props: {
        /**
         * @description This is the element within the DOM to target when moving the slotted
         * component. If a String is passed, it is converted into a jQuery object.
         */
        target: {
            type: [jQuery, String, HTMLElement],
            required: true,
        },
        /**
         * @description This is a selector to keep from mounting multiple copies, if
         * a single copy is desired.
         */
        uniqueSelector: {
            type: [jQuery, String, HTMLElement],
        },
        /**
         * @description The type of movement the component will make. The values are:
         *      - prepend => Adds the slotted component as the first child element within the target
         *                   element.
         *      - append => Adds the slotted component as the last child element within the target
         *                  element.
         *      - replace => Replaces the target element with the slotted component.
         *      - before => Adds the slotted component as a sibling before the target element.
         *      - after => Adds the slotted component as a sibling after the target element.
         */
        mode: {
            type: String,
            default: "after",
            // eslint-disable-next-line jsdoc/require-jsdoc
            validator(value) {
                const validValues = ["prepend", "append", "replace", "before", "after"];
                return validValues.includes(value);
            },
        },
        /**
         * @description The HTML tag to use for the temporary element added to the DOM. This element
         * should be replaced with the slotted component.
         */
        tag: {
            type: String,
            default: "div",
        },
        // Hide the target when teleporting.
        hide: {
            type: Boolean,
            default: false,
        },
        // Wait for the target to be present before teleporting.
        // Can probably be removed and used in combination with WaitFor
        lazy: {
            type: Boolean,
            default: false,
        },
        // Same as lazy but only occurs once.
        // Can probably be removed and used in combination with WaitFor
        lazyOnce: {
            type: Boolean,
            default: false,
        },
        // Re-teleport if the target is re-added to the page.
        // Can probably be removed and used in combination with WaitFor. I'm less sure on this.
        persist: {
            type: Boolean,
            default: false,
        },
    },
    // eslint-disable-next-line jsdoc/require-returns
    /**
     * @property {string} mount_id - The unique ID for the temporary element created.
     * @property {boolean} mountReady - Indicator is the temporary element has been created and is
     * ready to be overridden.
     * @property {string} mountTo - The CSS selector for the temporary mount element.
     * @property {string} mountElement - The HTML template for the temporary mount element.
     */
    data() {
        const mount_id = nanoid();
        const tag = this.tag.toLowerCase();
        return {
            mount_id,
            mountReady: false,
            mountTo: `[mount_id="${mount_id}"]`,
            mountElement: `<${tag} mount_id="${mount_id}"></${tag}>`,
            observer_sig: null,
            abort: false,
            lazyDisconnect: () => {},
            persistObserverAdded: false,
            mountKey: nanoid(),
            targetElement: [],
        };
    },
    computed: {
        /**
         * @returns {boolean} True if `this.target` is a selector string.
         */
        isStringTarget() {
            return typeof this.target === "string";
        },
        /**
         * @returns {boolean} Indicates whether the target element has been found.
         */
        isElementFound() {
            return this.targetElement.length > 0;
        },
        /**
         * @returns {object} Slot object.
         */
        self() {
            return this.$slots.default[0];
        },
    },
    methods: {
        /**
         * Loads the teleported component once the target is present on the page.
         *
         * It does this by creating a MutationObserver on the root <html> element. When a new
         * element is added to the DOM, the observer checks if the new element is the target the
         * Teleport is looking for. Once the target is found, the observer is disconnected.
         *
         * @returns {object} Lazy Load functions.
         */
        lazyLoad() {
            const app = this;
            return {
                /**
                 * MutationObserver handler for observer below.
                 */
                handler() {
                    let stop = false;
                    if (app.isStringTarget) {
                        // For string targets (ie CSS selectors), jQuery.observe only calls the handler
                        // when a matching element is added.
                        app.getTarget(this);
                        stop = app.lazyOnce;
                    } else {
                        app.getTarget();
                    }
                    if (app.targetElement.length) {
                        app.executeModeAction();
                        stop = app.lazyOnce;
                    }
                    if (stop) {
                        app.lazyLoad().disconnect();
                    }
                },
                /**
                 *
                 */
                disconnect() {
                    app.$.ready(() => {
                        // Wait for the DOM to finish loading before disconnecting the observer. This
                        // prevents a weird condition where disconnecting an observer can prevent an
                        // unrelated observer from registering.
                        app.$logger.debug("Disconnecting lazyLoad observer.");
                        app.$("html").disconnect("added", this.selector, this.handler);
                    });
                },
                /**
                 * Create a unique selector so observers have a unique signature in jQuery.observe.
                 * @returns {string} Unique selector for observer.
                 */
                get selector() {
                    let selector = `:not("#${nanoid()}")`;
                    if (app.isStringTarget) {
                        // Combine the target selector and the unique selector.
                        selector = app.target + selector;
                    }
                    return selector;
                },
                /**
                 *
                 */
                run() {
                    app.$logger.debug("Using LazyLoading");
                    // eslint-disable-next-line jsdoc/require-jsdoc
                    app.$("html").observe("added", this.selector, this.handler);
                },
            };
        },
        /**
         * @returns {object} Functions for portKey.
         */
        currentPortKey() {
            const app = this;
            return {
                /**
                 * Adds the portal key to the `data-portKey` attribute of the slot and to the jQuery data on `$("html")`.
                 */
                set() {
                    app.$logger.debug(`Setting Port Key ${app.uniqueSelector}`);
                    app.addSlotAttr("data-portKey", app.uniqueSelector);
                    const keys = app.$("html").data("port keys") || {};
                    // If the portal key has already been registered, skip registration.
                    if (!keys[app.uniqueSelector]) {
                        keys[app.uniqueSelector] = false;
                        app.$("html").data("port keys", keys);
                    }
                },
                /**
                 * Update the jQuery data on `$("html")` indicating the portal key has been used.
                 */
                reset() {
                    if (app.uniqueSelector) {
                        const keys = app.$("html").data("port keys") || {};
                        keys[app.uniqueSelector] = false;
                        app.$("html").data("port keys", keys);
                    }
                },
                /**
                 * Update the jQuery data on `$("html")` indicating the portal key has been used.
                 * @returns {boolean} Indicates whether the portKey has been used.
                 */
                use() {
                    if (app.uniqueSelector) {
                        const keys = app.$("html").data("port keys") || {};
                        keys[app.uniqueSelector] = true;
                        app.$("html").data("port keys", keys);
                    }
                    return true;
                },
                /**
                 * @returns {boolean} True if a portKey is provided and it was already used by another Teleport.
                 */
                get isUsed() {
                    if (!app.uniqueSelector) {
                        return false;
                    }
                    const keys = app.$("html").data("port keys") || {};
                    // Check the jQuery data and the DOM for the portal key.
                    if (
                        keys[app.uniqueSelector] ||
                        app.$(`[data-portKey="${app.uniqueSelector}"]`).length > 0
                    ) {
                        app.$logger.debug(`Port Key: ${app.uniqueSelector} has already been used.`);
                        return true;
                    }
                    return false;
                },
            };
        },

        /**
         * Runs the primary teleport action based on the `mode`.
         */
        executeModeAction() {
            this.$logger.debug(`Running in "${this.mode}" mode`);
            this.mountReady = false;
            // jQuery action to take depending on the type of this component.
            const actionMap = {
                prepend: () => this.targetElement.prepend(this.mountElement),
                append: () => this.targetElement.append(this.mountElement),
                replace: () => this.targetElement.attr("mount_id", this.mount_id),
                before: () => this.targetElement.before(this.mountElement),
                after: () => this.targetElement.after(this.mountElement),
            };
            const setMountReady = () => (this.mountReady = true);
            // Execute the actionMap function. When it is successful, set this.mountReady.
            if (actionMap[this.mode]()[0] && setMountReady() && this.currentPortKey().use()) {
                this.$logger.debug("Success");
                if (this.hide) {
                    this.targetElement.hide();
                }
                if (this.persist && !this.persistObserverAdded) {
                    this.persistObserver();
                    this.persistObserverAdded = true;
                }
                this.$nextTick(() => {
                    this.$emit("ready");
                });
            }
        },
        /**
         *
         */
        persistObserver() {
            this.addSlotAttr("persist-id", this.mount_id);
            const app = this;
            // Create a unique selector so observers have a unique signature in jQuery.observe
            let selector = `:not("#${nanoid()}")`;
            if (this.isStringTarget) {
                // Combine the target selector and the unique selector.
                selector = this.target + selector;
            }
            /**
             *
             */
            function handler() {
                app.getTarget();
                if (app.targetElement.length) {
                    const currentTeleportElement = app.$(`[persist-id="${app.mount_id}"]`);
                    const targetPresent = app.targetElement.length > 0;
                    const elementPresent = currentTeleportElement.length > 0;
                    if (targetPresent && !elementPresent) {
                        app.mountKey = nanoid();
                    }
                }
            }
            this.$("html").observe("added childlist", selector, handler);
        },

        /**
         * Converts the target into a jQuery object. If no target is supplied, `this.target` is used instead.
         * If the portKey for the teleport has already been used, the teleport is aborted.
         * @param {jQuery | string | HTMLElement | null} target The target to convert into a jQuery object.
         */
        getTarget(target = null) {
            if (this.currentPortKey().isUsed) {
                this.abortTeleport();
            }
            this.targetElement = this.$(target || this.target);
        },
        /**
         * @param {string} name Name for slot attr.
         * @param {string} value Value for slot attr.
         */
        addSlotAttr(name, value) {
            const slot = this.$slots.default[0];
            const data = {};
            data[name] = value;
            // Initialize slot data as an object. Otherwise, data can be undefined.
            slot.data = merge({}, slot.data);
            // Update slot attributes with the `data-portKey` attribute.
            slot.data.attrs = merge({}, slot.data.attrs, data);
        },

        /**
         * Stops mounting the slot and other Teleport processes.
         */
        abortTeleport() {
            this.abort = true;
            this.lazyLoad().disconnect();
            this.$logger.debug("Aborting");
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        if (this.portKey) {
            this.currentPortKey().set();
        }
        // Normalize the target into a jQuery element.
        this.getTarget();
        // Execute the mode action if target found.
        if (this.targetElement.length) {
            this.$logger.debug("Target found on mount.");
            this.executeModeAction();
        }

        // If lazy is True and the target cannot be found, lazy load the mode.
        else if (this.lazy || this.lazyOnce) {
            this.lazyLoad().run();
        }
    },
};
</script>

<style></style>
