/**
 * @description A class to execute a callback function after a set amount of time in milliseconds has passed.
 * @property {number} msToWait - Number of milliseconds to wait before executing callback function.
 * @property {boolean} startImmediately - Boolean to determine whether start function is called after class instance is constructed.
 * @property {number} _timeoutId - Identifies the timer created by the call to setTimeout by start function it is passed to clearTimeout to cancel timeout when stop is called.
 * @property {Promise | null} _timeoutPromise - Promise that when resolved makes a call to setTimeout which calls the callback.
 * @property {Function} _callback - Callback function to be used by the start method.
 */
class WaitForTime {
    /**
     * @param {number} msToWait The time to wait before running the callback function.
     * @param {boolean} startImmediately If True, the `start` function is called immediately after class instance is constructed.
     * @param {Function} callback The callback function to be used by the start method.
     */
    constructor(msToWait, startImmediately = true, callback = () => {}) {
        this.msToWait = msToWait;
        this.startImmediately = startImmediately;
        this._timeoutId = 0;
        this._timeoutPromise = null;
        this._callback = callback;
    }

    /**
     * Starts the timeOut function for the provided callback.
     * @returns {Promise}
     */
    async start() {
        let result;
        if (!this._timeoutId) {
            this._timeoutPromise = new Promise((r) => {
                this._timeoutId = setTimeout(() => {
                    result = this._callback?.();
                    r();
                }, this.msToWait);
            });
            await this._timeoutPromise;
        }
        return result;
    }

    /**
     * Stops the currently running timeOut function and resolves the existing Promise.
     */
    stop() {
        clearTimeout(this._timeoutId);
        Promise.resolve(this._timeoutPromise);
        this._timeoutId = 0;
    }

    /**
     * Stop the currently running timeOut function and start it again. This resets the countdown timer for the callback.
     * @returns {Promise}
     */
    restart() {
        this.stop();
        return this.start();
    }
}

/**
 * @typedef {WaitForTime|Promise} waitForTime
 * @property {Function} start Starts the timeOut function for the provided callback.
 */

/**
 * @param {number} msToWait Number of milliseconds to wait.
 * @param {boolean} startImmediately Boolean indicating whether or not to execute callback immediately upon execution.
 * @param {Function} callback The callback function to be used by the start method.
 * @returns {waitForTime|Promise}
 */
export function waitForTime(msToWait, startImmediately = true, callback = () => {}) {
    const obj = new WaitForTime(msToWait, startImmediately, callback);
    if (startImmediately) {
        return obj.start();
    }
    return obj;
}
