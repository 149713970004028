import { Logger } from "@/core/utils";

export default {
    /**
     * Log message to enabled providers for LOG level.
     *
     * @param {Vue} Vue A frontend instance to be used with.
     * @param {object} options An object specifying which logging providers to use
     * ex. {useConsole: true}.
     */
    install(Vue, options) {
        const getComponentName = (component) =>
            component?.$vnode?.componentOptions?.Ctor?.extendOptions?.name ||
            component?.$vnode?.componentOptions?.Ctor?.superOptions?.name;
        // Get the Component Name and all of the parent names.
        const getComponentPathName = (component) => {
            let name = getComponentName(component);
            let parent = component?.$parent;
            while (parent?.$vnode) {
                name = `${getComponentName(parent)}.${name}`;
                parent = parent?.$parent;
            }
            return name;
        };
        Vue.prototype.$logger = new Logger();
        Vue.prototype.$logger.enabled = options.useConsole;
        Vue.mixin({
            /**
             *
             */
            beforeCreate() {
                this.$logger = new Logger(getComponentPathName(this), {
                    enabled: options.useConsole,
                });
            },
        });
    },
};
