<template>
    <div>
        <div v-for="mountPoint in mountTo">
            <Teleport :target="mountPoint" mode="append" target-slim>
                <account-link-override v-if="useManageSubscriptionsOverride" :href="href"></account-link-override>
                <li v-else :class="`${appendClasses(mountPoint)} rca-manage-subscriptions`">
                    <a :class="linkClasses(mountPoint)" :style="cssVariables" :href="href" target="_blank">
                        {{ $t("accounts.manage_subscriptions_label") }}
                    </a>
                </li>
            </Teleport>
        </div>
    </div>
</template>

<script>
import { listElementStyleClasses } from "@/core/utils";
import { concat, intersection } from "lodash";
import AccountLinkOverride from "@/bigcommerce/pages/account/AccountLinkOverride";

export default {
    name: "AccountSubscriptionLink",
    props: ["customClassId", "href"],
    components: {
        Teleport: () => import("@/core/vue/components/Teleport"),
        AccountLinkOverride,
    },
    methods: {
        /**
         *
         * @param {HTMLElement} navElement  Parent navigation element (unordered list) containing navigation links.
         * @returns {string} The classes to be added to the manage subscription link.
         */
        appendClasses(navElement) {
            const listEls = navElement.querySelectorAll("li");

            /**
             * @type {Array<Array>} List where each item is a list of classes.
             */
            let classes = Array.from(listEls).map((child) => Array.from(child.classList));
            /**
             * @type {Array} List of classes which are present on all classes.
             */
            const sharedClasses = intersection(...classes);
            let sharedClassesJoined = sharedClasses.join(" ");

            /** If there are no shared classes, return the classes from the last element. */
            if (sharedClassesJoined === "") {
                if (listEls.length !== 0) {
                    sharedClassesJoined = listEls[listEls.length - 1].className;
                }
            }
            return sharedClassesJoined;
        },

        /**
         * Prepare classes that will be added to the link element.
         * @param {HTMLElement} navElement Parent navigation element (unordered list) containing navigation links.
         * @returns {string} All classes as string.
         */
        linkClasses(navElement) {
            try {
                const classes = listElementStyleClasses("li > a", navElement);
                if (!this.isLiveLink) {
                    classes.push("rca-link--disabled", "rca-link--no-hover", ".rca-manage-subscription");
                }
                return classes.join(" ");
            } catch {
                return "navBar-item navBar-action";
            }
        },
    },
    computed: {
        /**
         * @returns {boolean} If True, a manage subscriptions link override html value has been provided.
         */
        useManageSubscriptionsOverride() {
            const html = this.$t("accounts.manage_subscriptions_html");
            return html.length > 0;
        },
        /**
         * Location to insert element.
         * @returns {string}
         */
        mountTo() {
            const selector = this.getCustomClass(this.customClassId);
            return document.querySelectorAll(selector);
        },
        /**
         * Check if link exists.
         * @returns {boolean}   True if href exists, false if not.
         */
        isLiveLink() {
            return !!this.href;
        },
        /**
         * Apply correct theme color for css attributes.
         *
         * @returns {object} Object should hold value for the --property --color.
         */
        cssVariables() {
            const theme = this.$store_objects?.theme_settings;
            return {
                "--color": theme ? theme["color-textSecondary"] : "inherit",
            };
        },
    },
};
</script>

<style>
a.rca-link--disabled.rca-link--no-hover {
    cursor: default;
}

a.rca-link--disabled.rca-link--no-hover:hover {
    color: var(--color);
}
</style>
