<template>
    <label
        class="rca-subscription-form__label"
        :class="{
            'rca-subscription-form__radio--selected': isSelected,
        }"
    >
        <input
            name="subscription-radio-option"
            type="radio"
            class="rca-subscription-form__radio"
            :class="`rca-subscription-form__radio--${radioClassType}`"
            :value="radioType"
            :disabled="subscriptionType.endsWith('_only')"
            :checked="isSelected"
        />
        <span>{{ radioLabel }}</span>
    </label>
</template>

<script>
export default {
    name: "SingleRadioOption",
    props: {
        isSelected: Boolean,
        radioType: {
            type: String,
            validator: (val) => ["subscription", "onetime"].includes(val),
        },
        radioLabel: String,
        subscriptionType: String,
    },
    computed: {
        /**
         * This function is for assigning classes for the respective radio option.
         * @returns {string} The Type of the option: subscription or otp.
         */
        radioClassType() {
            return this.radioType === "onetime" ? "otp" : this.radioType;
        },
    },
};
</script>
