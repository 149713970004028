import { BigcommerceCart, BigcommerceCustomer } from "@/bigcommerce/utils";
import { RechargeMixin } from "@/core/utils";

import { globalConsoleLogger } from "@/core/utils/logger/loggerUtils";

export const appSupportedPageTypes = {
    product: "Product",
    account: "Account",
    multiple_products: "Multiple Products",
    cart: "Cart",
    checkout: "Checkout",
    default: "Default",
};

export const bigcommercePageTypeMap = {
    product: ["product"],
    account: [
        "account_addressbook",
        "account_downloaditem",
        "account_inbox",
        "editaccount",
        "account_orderstatus",
        "account_order",
        "account_recentitems",
        "account_paymentmethods",
        "account_saved_return",
        "account_returns",
        "wishlists",
        "add-wishlist",
        "wishlist",
    ],
    multiple_products: ["category", "brand", "brands"],
    cart: ["cart"],
    checkout: ["checkout"],
    default: [
        "createaccount_thanks",
        "createaccount",
        "forgotpassword",
        "login",
        "getnewpassword",
        "compare",
        "account_ordersinvoice_print",
        "blog",
        "blog_post",
        403,
        404,
        "error",
        "default",
        "page",
        "page_contact_form",
        "giftcertificates_balance",
        "giftcertificates",
        "giftcertificates_redeem",
        "search",
        "sitemap",
        "rss",
        "newsletter_subscribe",
        "unsubscribe",
        "hibernation",
    ],
};

const altPageTypeMap = {
    product: [(app) => !!document.querySelector(app.getCustomClass("product_view"))],
    account: [],
    multiple_products: [],
    cart: [],
    checkout: [
        () =>
            window.location.pathname.includes("/checkout") &&
            !window.location.pathname.includes("/order-confirmation"),
    ],
};

/**
 * Primary entrypoint for Vue to interact with BigCommerce helpers.
 */
export class BigcommerceVue {
    /**
     * @param {Vue} app The Vue application.
     */
    constructor(app) {
        this.app = app;
        this.cart = new BigcommerceCart({
            core: app,
            logger: app.$logger,
        });
        this.customer = new BigcommerceCustomer({
            app_client_id: process.env.CURRENTCUSTOMER_APP_CLIENT_ID,
        });
    }

    /**
     * Queries the BigCommerce Checkout data and returns the cart helper.
     * @param waitForRefresh
     * @returns {BigcommerceCart} The BigcommerceCart object.
     */
    async getUpdatedCart(waitForRefresh = false) {
        const cartPromise = this.cart.getCheckoutData();
        if (!waitForRefresh && this.cart.hasStoredCheckout) {
            return this.cart;
        }
        return await cartPromise;
    }

    /**
     * Get the current page type.
     * @returns {object} The supported page types.
     * @property {boolean} isProduct True if product page and product page enabled.
     * @property {boolean} isAccount True if account page.
     * @property {boolean} isMultipleProducts True if collection page and collection page enabled.
     * @property {boolean} isCart True if cart page and cart page enabled.
     * @property {boolean} isCheckout True if checkout page and checkout page enabled.
     * @property {boolean} isDefault True if no other page options apply.
     * @property {string} currentPage The specific page currently loaded.
     * @property {string} currentPageType The type of page currently loaded.
     */
    get currentPageType() {
        const currentBigcommercePage = this.app.$store_objects?.page_type;
        let currentPageType = appSupportedPageTypes.default;
        for (const pageType in bigcommercePageTypeMap) {
            if (
                bigcommercePageTypeMap[pageType].some((bcType) => bcType === currentBigcommercePage)
            ) {
                currentPageType = appSupportedPageTypes[pageType];
            }
        }
        if (currentPageType === appSupportedPageTypes.default) {
            Object.entries(altPageTypeMap).forEach(([pageType, checks]) => {
                if (checks.some((func) => func(this.app))) {
                    globalConsoleLogger.meta("Using alternative page type mapping.");
                    currentPageType = appSupportedPageTypes[pageType];
                }
            });
        }
        const isProduct =
            currentPageType === appSupportedPageTypes.product &&
            (this.app.settings.pages?.product?.enabled ?? true);
        return {
            // True if product page and product page enabled
            isProduct,
            // True if account page
            isAccount: currentPageType === appSupportedPageTypes.account,
            // True if collection page and collection page enabled
            isMultipleProducts:
                currentPageType === appSupportedPageTypes.multiple_products &&
                (this.app.settings.pages?.collection?.enabled ?? true),
            // True if cart page and cart page enabled
            isCart:
                currentPageType === appSupportedPageTypes.cart &&
                (this.app.settings.pages?.cart?.enabled ?? true),
            // True if checkout page and checkout page enabled
            isCheckout:
                currentPageType === appSupportedPageTypes.checkout &&
                (this.app.settings.pages?.checkout?.enabled ?? true),
            // True if no other page options apply
            isDefault: currentPageType === appSupportedPageTypes.default,
            isSubscriptionProduct:
                isProduct &&
                !!this.app.$store_data?.getProductByBCProductID(this.app.$store_objects.product.id)
                    ?.subscriptionModifier?.id,
            currentPage: currentBigcommercePage,
            currentPageType,
        };
    }
}

export default {
    /**
     * @param {object} Vue - The Vue Object.
     * @param {object} root0 An object containing the next attributes.
     * @param {object} root0.storeObjectsData The data of objects in vuex store.
     */
    install(Vue, { storeObjectsData }) {
        Vue.mixin({
            mixins: [RechargeMixin],
            computed: {
                /**
                 * @returns {string} A key.
                 */
                lsKey() {
                    return `${this.storeHash}-rca-key`;
                },
            },
            // eslint-disable-next-line jsdoc/require-jsdoc
            data() {
                return {
                    $store_objects: storeObjectsData,
                    storeHash: storeObjectsData.store_hash,
                    store_hash: storeObjectsData.store_hash,
                    currentCartId: storeObjectsData.cart_id,
                    app: {},
                    bigcommerce: null,
                };
            },
            // eslint-disable-next-line jsdoc/require-jsdoc
            created() {
                this.$store_objects = storeObjectsData;
                const app = this;
                this.bigcommerce = new BigcommerceVue(app);
            },
        });
    },
};
