import { globalConsoleLogger } from "@/core/utils/logger/loggerUtils";

/**
 *
 */
class HideWithStyle {
    /**.
     * This is the utility class used for hiding bigcommerce elements and showing them back.
     * We should hide specific bigcommerce elements as soon as we can and wait for
     * adapter initialization to finish. Then we show these elements.
     * It is important for the user experience, so users do not see elements popping in and
     * out on page initialization.
     *
     * How to use:
     *
     * @example
     * // initialize hider
     * const hider = HideWithStyle('.checkout-step--payment .checkout-view-content');
     * // call this as soon as possible
     * hider.hide();
     * // call this when our frontend finishes initialization
     * hider.show();
     */

    /**
     * Used for hiding bigcommerce elements before we initialize ours.
     * @param {string} selector - Selector of the object we are targeting.
     */
    constructor(selector) {
        this.selector = selector;
        this.styleNode = null;
    }

    /**
     * Hide element.
     */
    hide() {
        globalConsoleLogger.meta(`HIDING: ${this.selector}`);
        this.styleNode = document.createElement("style");
        this.styleNode.innerHTML = ` ${this.selector} { display: none !important; } `;
        document.head.appendChild(this.styleNode);
    }

    /**
     * Return element to original state.
     */
    show() {
        globalConsoleLogger.meta(`SHOWING: ${this.selector}`);
        this.styleNode.remove();
        this.styleNode = null;
    }
}

export { HideWithStyle };
