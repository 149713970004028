<template></template>

<script>
import { isMatch } from "lodash";
import { adapterBackendMixin } from "@/core/utils";
import { waitForTrue } from "@/core/utils/waitFor/waitForTrue";

const logLevel = "meta";

export default {
    name: "ProductSync",
    mixins: [adapterBackendMixin],
    props: {
        /**
         * @type {RcaDataProduct | object}
         */
        product: Object,
    },
    computed: {
        /**
         * @returns {jQuery} The jQuery object for the product's modifier.
         */
        subscriptionModiferEl() {
            if (this?.product?.modifierEl?.length) {
                return this.product.modifierEl.find("select");
            }
            const modifierId = this.product?.subscriptionModifier?.id;
            return this.$(`[name="attribute[${modifierId}]"]`);
        },

        /**
         * @returns {boolean} If True, the product is a subscription product with modifer data in
         * the CDN.
         */
        isValidSyncTarget() {
            return this.$logger.checkAndLog(this.product.isSubscription, {
                ifTrue: `Product ${this.product.id} is a valid sync target.`,
                ifFalse: `Product ${this.product.id} is NOT a valid sync target.`,
                level: logLevel,
            });
        },
    },
    methods: {
        /**
         * @returns {boolean} If True, the modifier frequencies match expected values.
         */
        doModifierFrequenciesMatchExpected() {
            const expectedData = this.product.subscriptionModifier;
            // Only use the first element since a product could have multiple copies on the same page.
            const options = this.subscriptionModiferEl.first().find("option").not("[value='']");
            const frequencyKeys = Object.keys(expectedData.frequencies);           
            const isCorrectId = () =>
                this.subscriptionModiferEl.attr("name")?.includes?.(expectedData.id);
            const hasCorrectValues = () => {
                const optionValues = options
                    .map(function () {
                        return this.value;
                    })
                    .get();
                return isMatch(optionValues, frequencyKeys);
            };
            const extra = {
                isCorrectId: isCorrectId(),
                hasCorrectValues: hasCorrectValues(),
            };
            return this.$logger.checkAndLog(
                extra.isCorrectId && extra.hasCorrectValues,
                {
                    ifTrue: `Product ${this.product.id} modifier matches.`,
                    ifFalse: `Product ${this.product.id} modifier does not match.`,
                    level: logLevel,
                    extra,
                }
            );
        },
        /**
         * @returns {Promise<boolean>} If True, this product should be synced.
         */
        async shouldSync() {
            if (this.settings.backend.disable_product_install == true) {
                return false;
            }
            const isModifierPresent = await this.isModifierPresent();
            if (isModifierPresent && this.doModifierFrequenciesMatchExpected()) {
                this.$logger.debug(`Product ${this.product.id} should NOT sync.`);
                return false;
            }
            this.$logger.debug(`Product ${this.product.id} should sync.`);
            return true;
        },
        /**
         * @returns {Promise<boolean>} If True, a modifier for this product was found on the page.
         */
        async isModifierPresent() {
            const timeout = 5000; // 5 seconds
            let idPresent = true;

            await waitForTrue(() => this.subscriptionModiferEl.length > 0, timeout).catch(() => {
                idPresent = false;
            });

            return this.$logger.checkAndLog(idPresent, {
                ifTrue: `Product ${this.product.id} modifier is present.`,
                ifFalse: `Product ${this.product.id} modifier is NOT present.`,
                level: logLevel,
            });
        },
        /**
         * Checks if the product should be synced. If it should, this method will then sync the
         * product.
         */
        async checkAndSync() {
            const shouldSync = await this.shouldSync();
            if (this.isValidSyncTarget && shouldSync) {
                await this.syncProduct();
            }
        },
        /**
         * Syncs the product via the Adapter API.
         */
        async syncProduct() {
            this.$logger.info(`Syncing Product ${this.product.id}`);
            await this.adapterBackend.syncProductByID(
                this.$store_objects.store_hash,
                this.product.id
            );
        },
    },
    /**
     *
     */
    mounted() {
        this.$logger.debug(`Checking sync for product ${this.product.id}`, {
            product: this.product,
            subscriptionModiferEl: this.subscriptionModiferEl,
        });
        this.checkAndSync();
    },
};
</script>

<style></style>
