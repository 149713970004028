<template>
    <label
        class="rca-subscription-form__button"
        :class="[
            {
                'rca-subscription-form__button--selected': isSelected,
            },
            `rca-subscription-form__button--${buttonClassType}`,
            `rca-button-${isLeftOrRight}`,
        ]"
    >
        <span :class="`rca-subscription-form-buttons-${buttonClassType}-text`">
            {{ buttonTitle }}
        </span>
        <br />
    </label>
</template>

<script>
// eslint-disable-next-line jsdoc/require-description-complete-sentence
/**
 * NOTE: Remember to set the CSS variables when using this component:
 * --background_color
 * --active_color
 * --font_color
 */
export default {
    name: "SingleButton",
    props: {
        isSelected: Boolean,
        /**
         * @type {"subscription"|"otp"}
         */
        buttonType: {
            type: String,
            validator: (val) => ["subscription", "onetime"].includes(val),
        },
        buttonTitle: String,
        /**
         * @type {"left"|"right"}
         */
        isLeftOrRight: {
            type: String,
            validator: (val) => ["left", "right"].includes(val),
        },
    },
    computed: {
        /**
         * This function is for assigning classes for the respective buttons.
         * @returns {string} The Type of the button: subscription or otp.
         */
        buttonClassType() {
            return this.buttonType === "onetime" ? "otp" : this.buttonType;
        },
    },
};
</script>

<style>
.rca-subscription-form__button {
    cursor: pointer;
    background: none;
    color: var(--font_color);
    display: inline-block;
    min-width: 144px;
    width: 50%;
    height: 100%;
    padding: 0.5em 0.2em;
    margin: 0;
    border-radius: 0;
    border: #917052 0.1em solid;
    text-align: center;
    flex: 1;
    font-size: 1em;
}

/**
* Styling for the button on the left or right respectively. To keep styling and custom classes separated
* and to avoid conflicts when the buttons change places.
*/
.rca-button-left {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    margin-right: 1px;
}

.rca-subscription-form__button--selected {
    box-shadow: 0 0 0 1px #917052;
    background: var(--background_color);
    color: var(--active_color);
}

.rca-button-right {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    margin-left: 1px;
}

.rca-subscription-form__button--selected {
    box-shadow: 0 0 0 1px var(--active_color);
}

@media only screen and (max-width: 400px) {
    .rca-subscription-form__button {
        font-size: 13px;
        padding: 0.5em 0.3em;
        width: calc(50vw - 30px);
        padding-top: 9px;
    }
}

@media only screen and (min-width: 401px) {
    .rca-subscription-form__button {
        width: 169px;
    }
}
</style>
