/**
 * @module EnablingApp
 * This module is responsible for actions related to the application's status of being "enabled".
 * This includes:
 *  - Enabling / Disabling the application
 *  - Getting the "enabled" status of the application
 *  - Determining when and under what circumstances the application should be enabled or disabled
 *  - Determining the "mode" of the application.
 */

/**
 * The service layer which contains all low-level logic. Each method is a "step" in a higher-level "process".
 * This layer should only be implemented by controllers.
 */
class Service {
    /**
     * @param {Vue} app The Vue component / application.
     */
    constructor(app) {
        this.app = app;
    }

    /**
     * Disable the application.
     * @param {string} errorMsg - The error message to display in the console.
     */
    disableApp(errorMsg) {
        if (!this.app.settingsLoader.isAppDisabled) {
            this.app.setAppDisabled();
        }
        this.app.$logger.error(errorMsg, { "Current Settings": this.app.settings });
        this.updateIsEnabled(false);
    }

    /**
     * @returns {boolean} `true` if no `RCA_DATA` can be found for the store.
     */
    get isMissingStoreData() {
        return !this.app.$store_data;
    }

    /**
     * @returns {boolean} - If recharge parameter is set with test or true.
     */
    get isTestModeEnabled() {
        return /[?&]recharge=(test|true)/i.test(window.location.search);
    }

    /**
     * @returns {boolean} `true` if the app is not enabled in the settings and test mode is not enabled.
     */
    get isDisabledInSettings() {
        return !(this.app.settings.enabled || this.isTestModeEnabled);
    }

    /**
     * @returns {boolean} `true` if this app is currently disabled via an automatic process and test mode is not enabled to override it.
     */
    get isAppAlreadyDisabled() {
        return !this.isTestModeEnabled && this.app.settingsLoader.isAppDisabled;
    }

    /**
     * Emits an event to update `isEnabled` on a parent component.
     * @param {boolean} status This value of `isEnabled`.
     */
    updateIsEnabled(status) {
        this.app.$emit("update:isEnabled", status);
    }
}

/**
 * @class EnablingApp
 * The primary controller class which contains all high-level processes.
 * This should only orchestrate the execution of service steps and not implement any logic directly.
 */
export class EnablingApp {
    /**
     * @param {Vue} app The Vue component / application.
     */
    constructor(app) {
        this.app = app;
        this.service = new Service(app);
    }

    /**
     * Checks if the app is or should be enabled. This will disable the app where appropriate.
     */
    checkIfAppIsEnabled() {
        const enabledApp = () => {
            this.app.$logger.info("Application is enabled.");
            this.service.updateIsEnabled(true);
        };

        // If `RCA_DATA` cannot be found, we disable the application.
        if (this.service.isMissingStoreData) {
            this.service.disableApp("RCA_DATA not found. Application is disabled");
        }
        // Next, we check the settings and for an indicator of "test mode".
        else if (this.service.isDisabledInSettings) {
            this.app.settingsLoader.refresh().then(() => {
                if (this.service.isDisabledInSettings) {
                    this.service.disableApp("App is disabled by RCA_SETTINGS");
                } else {
                    enabledApp();
                }
            });
        }
        // If the app was disabled earlies by one of the steps above, we simply send a message to the console.
        else if (this.service.isAppAlreadyDisabled) {
            this.service.disableApp(
                "Application is disabled. Wait a few seconds before reloading again."
            );
        }
        // If we make it to here, the app is enabled.
        else {
            enabledApp();
        }
    }
}
