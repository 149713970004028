/**
 *
 */
import { isEqual } from "lodash";
/**
 * Watches for a change in the result of `watch`.
 *
 * @property {Function} watch - The function to watch the return value of.
 * @property {Function} callback - The function to call when the `checker` function returns `true`.
 * @property {boolean} once - If true, the callback function will only be called a single time.
 * @property {Logger|console} $logger - The logger to use within the class.
 * @property {number} interval - The time (in milliseconds) between checking the `watch` return value.
 * @property {Function} checker - The function called the compare the last `watch` return value and the current return value.
 *  This function is passed `oldResult` and `newResult` as arguments. By default, this returns `true` if the two value are not equal.
 */
export class Watcher {
    /**
     * @param {Function} watch - The function to watch the return value of.
     * @param {Function} callback - The function to call when the `checker` function returns `true`.
     * @param {object} options - Options for configuring the watcher.
     * @param {boolean} options.once - If true, the callback function will only be called a single time.
     * @param {Logger|console} options.logger - The logger to use within the class.
     * @param {number} options.interval - The time (in milliseconds) between checking the `watch` return value.
     * @param {Function} options.checker - The function called the compare the last `watch` return value and the current return value.
     *  This function is passed `oldResult` and `newResult` as arguments. By default, this returns `true` if the two value are not equal.
     * @property {boolean} isStarted - Indicator if the watcher has been started.
     * @property {boolean} isDisabled - Indicator if the watcher has been disabled. A disabled watcher cannot be started.
     */
    constructor(
        watch,
        callback = null,
        {
            once = false,
            logger = {},
            interval = 50,
            checker = (oldResult, newResult) => !isEqual(oldResult, newResult),
        } = {}
    ) {
        this.watch = watch;
        this.interval = interval;
        this.callback = callback;
        if (!this.callback) {
            this.callback = this.stop;
        }
        this.checker = checker;
        this._stop = null;
        this._lastResult = undefined;
        this.once = once;
        this.isStarted = false;
        this.isDisabled = false;
        this.$logger = logger;
    }
    /**
     * Stop the watcher and prevent it from starting again.
     */
    disable() {
        if (!this.isDisabled) {
            this.$logger.callee(null).meta("Disabled");
            this.isDisabled = true;
            this.stop();
        }
    }
    /**
     * Enable the watcher and allow it to start again.
     */
    enable() {
        this.isDisabled = false;
    }
    /**
     * The callback handler used by the watcher.
     */
    handler() {
        if (this.isStarted && !this.isDisabled) {
            const newResult = this.watch();
            const oldResult = this._lastResult !== undefined ? this._lastResult : newResult;
            this._lastResult = newResult;
            if (this.checker(oldResult, newResult)) {
                this.$logger.meta("Callback called", { oldResult, lastResult: this._lastResult });
                this.callback(this._lastResult);
                if (this.once) {
                    this.disable();
                }
            }
        } else {
            this.$logger.warn("Empty handler ran");
        }
    }

    /**
     * Starts checking the value of the watched function.
     */
    start() {
        const self = this;
        if (!this.isStarted && !this.isDisabled) {
            this.$logger.callee(null).meta("Started");
            this._stop = setInterval(self.handler.bind(self), self.interval);
            this.isStarted = true;
        }
    }
    /**
     * Stops checking the value of the watched function.
     */
    stop() {
        if (this.isStarted || this._stop) {
            this.$logger.callee(null).meta("Stopped");
            clearInterval(this._stop);
            this._stop = null;
            this.isStarted = false;
        }
    }
}
