/**
 * --- State ---
 * -------------
 * https://vuex.vuejs.org/guide/state.html#single-state-tree
 * Vuex uses a single state tree - that is, this single object contains all your application level
 * state and serves as the "single source of truth." This also means usually you will have only one
 * store for each application.
 */
const state = {
    checkout: {},
};

/**
 * --- Getters ---
 * ---------------.
 Https://vuex.vuejs.org/guide/getters.html#property-style-access
 Vuex allows us to define "getters" in the store. You can think of them as computed properties
 for stores. Like computed properties, a getter's result is cached based on its dependencies, and
 will only re-evaluate when some of its dependencies have changed.

 You can also pass arguments to getters by returning a function. This is particularly useful when
 you want to query an array in the store. For example,.
@example
  ```
     getters: {
        getTodoById: (state) => (id) => {
          return state.todos.find(todo => todo.id === id)
        },
       doneTodos: state => {
         return state.todos.filter(todo => todo.done)
       }
     }
  ```
 */
const getters = {
    /**
     *
     * @param state
     */
    getCart: (state) => {
        return state.checkout?.cart ?? {};
    },
    /**
     *
     * @param state
     */
    getCheckout(state) {
        return state.checkout;
    },
};

/**
 * --- Mutations ---
 * -----------------.
 * @example // begin
 https://vuex.vuejs.org/guide/mutations.html
 The only way to actually change state in a Vuex store is by committing a mutation. You cannot
 directly call a mutation handler (which must be synchronous). To invoke a mutation handler, you
 need to call `store.commit` with its function name.

 Example:
 ```.
 increment (state, payload) {
    state.count += payload.amount
  }
  store.commit('increment', {
    amount: 10
  })
 ```.
 // end
 */
const mutations = {
    /**
     *
     * @param state
     * @param payload
     */
    setCheckout(state, payload) {
        state.checkout = payload;
    },
};

/**
 * --- Actions ---
 * ---------------
 * https://vuex.vuejs.org/guide/actions.html#dispatching-actions
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 *
 * Actions are triggered with the `store.dispatch` with its function name.
 */
const actions = {
    /**
     *
     * @param context
     * @param checkoutData
     */
    updateCheckoutData(context, checkoutData) {
        if (context.state.checkout.updatedTime !== checkoutData.updatedTime) {
            context.commit("setCheckout", checkoutData);
        }
    },
};

export default {
    // https://vuex.vuejs.org/guide/modules.html#namespacing
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
