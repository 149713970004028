import jQuery from "jquery-slim";
import { getPathSelector } from "@/core/libs/jquery/jquery-path";
require("./jquery-observer");

/**
 * @returns {string} Selector.
 * @param {object} param - Element selector.
 * @param {HTMLElement} param.target - HTML element being selected.
 * @param {boolean} param.withClasses - List of included classes.
 * @param {boolean} param.withAttributes - List of included attributes.
 * @param {Function} param.excludeClasses - Function that will return boolean when class is excluded.
 * @param {Function} param.excludeAttributes - Function that will return boolean for attributes with certai value.
 */
jQuery.fn.selectorPath = function ({
    target = undefined,
    withClasses = true,
    withAttributes = true,
    excludeClasses = () => false,
    excludeAttributes = () => false,
} = {}) {
    return getPathSelector(this, target, {
        withClasses,
        withAttributes,
        excludeClasses,
        excludeAttributes,
    });
};

// eslint-disable-next-line jsdoc/check-types
/**
 * @returns {Object<string, *>} Key/Value pairs of all attributes on the element.
 */
jQuery.fn.attrs = function () {
    let obj = {};
    jQuery.each(this[0]?.attributes, function () {
        if (this?.specified) {
            obj[this.name] = this.value;
        }
    });
    return obj;
};

export default jQuery;
