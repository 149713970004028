import { BaseMutationObserver } from "@/core/utils";
import { throttle } from "lodash";
import $ from "@/core/libs/jquery";
import { customClasses } from "@/core/utils/customClassesHelper";
import { getCustomClass } from "@/bigcommerce/vue/mixins/custom-classes";

const DEFAULT_SELECTORS = customClasses.getSelectors("additional_checkout_buttons", {
    asString: true,
});
const CHECKOUT_PAGE_SELECTORS = customClasses.getSelectors("checkout_additional_payment_options", {
    asString: true,
});

/**
 * @typedef {object} AdditionalCheckoutButtonsObserverArgs Root.
 * @property {Function|DebouncedFunc|DebouncedFuncLeading} isRechargeCart Function that returns a boolean indicating if the current cart is a ReCahrge Cart.
 * @property {boolean} isCheckoutPage If True, the current page is a Checkout page.
 */

// const logger = new Logger("HideAdditionalCheckoutButtons");

/**
 * Hide additional checkout buttons on customer section for the checkout page and paragraph "Or continue with".
 */
function hideCheckoutPageCheckoutButtons() {
    const checkoutButtonEls = $(CHECKOUT_PAGE_SELECTORS);
    if (checkoutButtonEls.length) {
        checkoutButtonEls.hide();
        checkoutButtonEls.prev("p").hide();
    }
}

/**
 * Hides all elements marked with the `cart_hide_subscription` custom class.
 */
function hideDefaultElements() {
    $(getCustomClass("cart_hide_subscription")).hide();
}

/**
 * Hide additional checkout buttons that could be present on any page type.
 * Only when we confirm that the cart is a recharge Cart.
 */
function hideDefaultCheckoutButtons() {
    $("body").find(DEFAULT_SELECTORS).hide();
}

/**
 * Show additional checkout buttons on customer section for the checkout page and paragraph "Or continue with".
 */
function showCheckoutPageCheckoutButtons() {
    const checkoutButtonEls = $(CHECKOUT_PAGE_SELECTORS);
    if (checkoutButtonEls.length) {
        checkoutButtonEls.show();
        checkoutButtonEls.prev("p").show();
    }
}

/**
 * Shows all elements marked with the `cart_hide_subscription` custom class.
 */
function showDefaultElements() {
    $(getCustomClass("cart_hide_subscription")).show();
}

/**
 * Show additional checkout buttons that could be present on any page type.
 * Only when we confirm that the cart is not a recharge Cart.
 */
function showDefaultCheckoutButtons() {
    $("body").find(DEFAULT_SELECTORS).show();
}

let isRunning = false;

/**
 * The function called by the mutation observer.
 * @param {AdditionalCheckoutButtonsObserverArgs} root Root.
 */
export function observerCallback({ isRechargeCart, isCheckoutPage }) {
    if (isRechargeCart() && !isRunning) {
        isRunning = true;
        if (isCheckoutPage) {
            hideCheckoutPageCheckoutButtons();
        }
        hideDefaultCheckoutButtons();
        hideDefaultElements();
        isRunning = false;
    } else if (!isRunning) {
        isRunning = true;
        if (isCheckoutPage) {
            showCheckoutPageCheckoutButtons();
        }
        showDefaultCheckoutButtons();
        showDefaultElements();
        isRunning = false;
    }
}

/**
 * @param {AdditionalCheckoutButtonsObserverArgs} root0 Root.
 * @returns {BaseMutationObserver}
 */
export function getAdditionalCheckoutButtonsObserver({ isRechargeCart, isCheckoutPage }) {
    const callback = throttle(() => observerCallback({ isRechargeCart, isCheckoutPage }), 100, {
        leading: false,
        trailing: true,
    });
    return new BaseMutationObserver("body", callback);
}
