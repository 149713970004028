<template></template>

<script>
import { mapActions } from "vuex";
import { EnablingApp } from "@/bigcommerce/pages/shared/EnablingApp";
// import { createCssElement } from "@/core/utils";

/**
 *
 */

export default {
    name: "OnAppLoad",
    props: {
        isEnabled: {
            type: Boolean,
            default: false,
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    data() {
        return {
            enablingApp: new EnablingApp(this),
        };
    },
    methods: {
        /**
         * Map the store actions for clearing expired customers and setting the global app state.
         */
        ...mapActions({
            setAppDisabled: "setAppDisabled",
            clearExpiredCustomers: "customer/clearExpiredCustomers",
        }),
    },
    /**
     * 1. Clear all expired customer data.
     * 2. Refresh all settings.
     */
    created() {
        this.clearExpiredCustomers({ key: this.lsKey });
        this.settingsLoader.refresh();
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        this.$logger.info(`Current Page Type: ${this.bigcommerce.currentPageType.currentPageType}`);
        this.$logger.log("Vue app mounted");
        this.enablingApp.checkIfAppIsEnabled();
    },
};
</script>

<style></style>
