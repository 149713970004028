<template></template>
<script>
import { getAdditionalCheckoutButtonsObserver } from "@/bigcommerce/utils/hideAdditionalCheckouts";
import SubscriptionDetailsMixin from "@/core/vue/mixins/subscription-details";
import CustomerMixin from "@/core/vue/mixins/customer";
import { debounce } from "lodash";
import { getUpdatedCartListener } from "@/bigcommerce/pages/shared/GetUpdatedCartObserver";

export default {
    name: "OnAppEnabled",
    props: {
        currentPageType: {
            type: Object,
            default: null,
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    data() {
        return {
            /** @type {object} Observers */
            observers: {
                additionalCheckoutButtons: null,
            },
        };
    },
    mixins: [CustomerMixin, SubscriptionDetailsMixin],
    methods: {
        /**
         * Add adapter custom classes to DOM elements.
         */
        addCustomClasses: function () {
            this.addCustomClassesToGroup("ALL");
            this.addCustomClassesToGroup("PRODUCT");
            this.addCustomClassesToGroup("CART");
            this.addCustomClassesToGroup("CATEGORY");
        },
        /**
         * If any page except Checkout is loaded, refresh the BigCommerce Cart data.
         */
        startUpdatedCartListener() {
            getUpdatedCartListener({
                getUpdatedCart: () => this.bigcommerce.getUpdatedCart(),
                isCheckout: () => this.bigcommerce.currentPageType.isCheckout,
            });
        },
        /**
         *
         */
        startAdditionalCheckoutButtonsObserver() {
            const isRechargeCart = () => {
                try {
                    return (
                        this.bigcommerce.cart.hasCheckoutData &&
                        this.bigcommerce.cart.isRechargeCart
                    );
                } catch {
                    return false;
                }
            };
            const args = {
                isCheckoutPage: this.bigcommerce.currentPageType.isCheckout,
                isRechargeCart: debounce(isRechargeCart, 50, { leading: true, trailing: false }),
            };
            this.$logger.debug("Starting additionalCheckoutButtonsObserver", {
                isCheckoutPage: args.isCheckoutPage,
                isRechargeCart: args.isRechargeCart(),
            });

            this.observers.additionalCheckoutButtons = getAdditionalCheckoutButtonsObserver(args);
            this.observers.additionalCheckoutButtons.start();
        },
    },
    /**
     *
     */
    async mounted() {
        const currentPageType = this.bigcommerce.currentPageType;
        this.addCustomClasses();
        // Get cart data as soon as we can. If it is the first time getting the cart, we wait for it.
        // Otherwise, we use the store data.
        if (this.$store_objects.cart_id) {
            const cart = await this.bigcommerce.getUpdatedCart();
            this.$logger.meta("", cart);
        }
        // Starts the observers
        this.startAdditionalCheckoutButtonsObserver();
        this.startUpdatedCartListener();

        // noinspection ES6MissingAwait
        this.preloadCustomerPortalUrl();

        this.$emit("update:currentPageType", currentPageType);
    },
    /**
     *
     */
    destroyed() {
        // Stops the observers
        this.observers.additionalCheckoutButtons.stop();
    },
};
</script>

<style></style>
