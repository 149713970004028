<template>
    <span class="rca-subscription-form" v-if="product.isSubscription">
        <span class="rca-subscription-form__checkbox-type" v-if="!product.isSubscriptionOnly">
            <label class="rca-subscription-form__label">
                <input
                    class="rca-subscription-form__checkbox rca-subscription-form-checkbox-input"
                    type="checkbox"
                    ref="checkbox"
                    v-model="isChecked"
                    :disabled="product.isSubscriptionOnly"
                />
                <span
                    class="rca-subscription-form-checkbox-combined-text"
                    v-html="checkboxForm.subscriptionMsg"
                ></span>
            </label>
        </span>
        <subscription-form-frequency
            :product="product"
            :show="showFrequencyInput || product.isSubscriptionOnly"
        />
    </span>
</template>

<script>
import SubscriptionDetailsMixin from "@/core/vue/mixins/subscription-details";
import SubscriptionFormFrequency from "../../../vue/components/SubscriptionForm/SubscriptionFormFrequency/SubscriptionFormFrequency.vue";
export default {
    name: "CheckboxForm",
    components: {
        SubscriptionFormFrequency,
    },
    mixins: [SubscriptionDetailsMixin],
    props: {
        /**
         * @type {RcaDataProduct}
         */
        product: Object,
    },
    /**
     *
     */
    data() {
        return {
            checkboxForm: {
                /**
                 * @type {string} The text to display for the subscription option.
                 */
                subscriptionMsg: this.subscriptionTextConfig(),
            },
            isChecked: false,
        };
    },
    methods: {
        /**
         *@returns {string} The formatted text message with its respective discount.
         */
        subscriptionTextConfig() {
            const formatText = this.configDiscountText(this.product.discountAmount);
            return this.combinedDisplayText(formatText);
        },
        /**
         *
         */
        updateSelectedValue() {
            this.selectedValue = this.isChecked ? "subscription" : "onetime";
        },
    },
    /**
     *
     */
    mounted() {
        this.isChecked =
            this?.settings?.pages?.product?.select_subscription_first ||
            this.product.isSubscriptionOnly;
        this.updateSelectedValue();
    },
    watch: {
        /**
         *
         */
        isChecked() {
            this.updateSelectedValue();
        },
    },
};
</script>

<style></style>
