import { getStoredData } from "@/core/interfaces/frontend_storage";
import { Logger } from "@/core/utils/logger/loggerClass";

const logger = new Logger(null, {
    enabled: true,
});

/**
 * Prints information about the currently running adapter frontend.
 * @returns {object} An object containing various key data about the currently running frontend.
 */
export function getAbout() {
    const aboutData = {
        version: getStoredData("main.version"),
        settings: getStoredData("main.settings"),
        checkout: getStoredData("cart.checkout"),
        adapterScriptElements: document.querySelectorAll("[src*='bc.js']"),
        overrides: {
            RCA_SETTINGS: window.RCA_SETTINGS,
            RCA_LOCALES: window.RCA_LOCALES,
        },
        globals: {
            RCA_DATA: window.RCA_DATA,
            RCA_store_objects: window.RCA_store_objects,
        },
    };
    let msg = `ReCharge Adapter Frontend (${aboutData.version.ui})`;
    if (aboutData.overrides.RCA_SETTINGS) {
        msg += "\n- Setting Overrides Found in RCA_SETTINGS";
    }
    if (aboutData.overrides.RCA_LOCALES) {
        msg += "\n- Translation Overrides Found in RCA_LOCALES";
    }
    let errors = [];
    if (aboutData.adapterScriptElements.length > 1) {
        errors.push(
            `Multiple bc.js scripts found. Found ${aboutData.adapterScriptElements.length}`
        );
    }
    if (!document.querySelectorAll("[src*='platform-data-']").length) {
        errors.push("No platform data script found");
    }
    if (errors.length > 0) {
        msg += `\nErrors Found:\n- ${errors.join("\n- ")}`;
    }

    logger.current(msg);
    return aboutData;
}

// TODO Add help functions or text like this to all interface functions.
// getAbout.help = () =>
//     console.log(`Prints information about the currently running adapter frontend.
// Returns:
//     {object} An object containing various key data about the currently running frontend.`);
