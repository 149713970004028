<template>
    <component v-if="template" :is="template"></component>
</template>

<script>
export default {
    /**
     * The provides a mechanism for merchants to override the Manage Subscriptions link with
     * custom HTML. The variable `$portalUrl$` is replaced within the HTML with `this.href`.
     */
    name: "AccountLinkOverride",
    props: ["href"],
    // eslint-disable-next-line jsdoc/require-jsdoc
    data() {
        return {
            template: null,
        };
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        const html = this.$t("accounts.manage_subscriptions_html").replaceAll(
            "$portalUrl$",
            this.href
        );
        this.template = html.length ? { template: html } : null;
    },
};
</script>

<style></style>
