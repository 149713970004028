import { isEqual, isMatch, isObject, isUndefined, omitBy, transform, values } from "lodash";

/**
 * Recursively apply a function to the values of an object.
 * @param {object} obj - The target object.
 * @param {Function} iterator - The function applied to each value.
 * @param {object} context - An object used for the `this` variable.
 * @returns {object} - The target object with all values replaced with the result of
 * iterator(value).
 */
export function deepMapValues(obj, iterator, context) {
    return transform(obj, function (result, val, key) {
        const _result = iterator.call(context, val, key, obj);
        if (isObject(val) && !isUndefined(_result) && !isMatch(_result, val)) {
            result[key] = _result;
        } else {
            result[key] = isObject(val) ? deepMapValues(val, iterator, context) : _result;
        }
    });
}

/**
 * Checks if a object is a nested object.
 * @param {object} obj - Object to check.
 * @returns {boolean}
 */
export function isNestedObject(obj) {
    return isObject(obj) && values(obj).some((i) => isObject(i));
}

/**
 * Recursively runs `omitBy` on the target object.
 * @param {object} obj - The target object.
 * @param {Function} predicate - The function used to determine if a value is omitted. Must return
 * a boolean.
 * @param {object} context - An object used for the `this` variable.
 * @returns {object} - The target object with all values removed where predicate = true.
 */
export function deepOmitBy(obj, predicate, context) {
    let newValue = omitBy(obj, predicate);
    newValue = deepMapValues(
        newValue,
        (item) => (isObject(item) ? omitBy(item, predicate) : item),
        context
    );
    if (!isEqual(obj, newValue)) {
        newValue = deepOmitBy(newValue, predicate, context);
    }

    return newValue;
}

/**
 * @param {*} value The value to check.
 * @returns {boolean} True if the value is a compliant native promise.
 */
export function isPromise(value) {
    return Boolean(
        value && typeof value.then === "function" && value[Symbol.toStringTag] === "Promise"
    );
}
