<template>
    <span v-if="isTargetFound" :dom-overwrite="true">
        <slot></slot>
    </span>
</template>

<script>
import { BaseMutationObserver } from "@/core/utils";
import jQuery from "jquery-slim";
import { isEmpty, merge, union, debounce } from "lodash";
import { getTargetElementObserver } from "@/core/vue/components/DomOverwrite/TargetElementObserver";

const MUTATION_BUFFER = 100; // ms

export default {
    name: "DomOverwrite",
    /**
     * @property {jQuery|string|HTMLElement} target The element or selector to target with the component.
     * @property {object} domProps Key/Value pairs of element properties to update. E.g. InnerHTML, text, etc.
     * @property {object} attrs Key/Value pairs of element attributes to update.
     * @property {'prepend'|'append'|'replace'|'before'|'after'|'last'|'first'} insert The insertion mode if a slot is attached.
     * - prepend => Adds the slotted component as the first child element within the target element.
     * - append => Adds the slotted component as the last child element within the target element.
     * - replace => Replaces the target element with the slotted component.
     * - before => Adds the slotted component as a sibling before the target element.
     * - after => Adds the slotted component as a sibling after the target element.
     * - last => Adds the slotted component as the last sibiling element within the target element's parent.
     * - first => Adds the slotted component as the first sibiling element within the target element's parent.
     * @property {boolean} observe If True, fire "mutated" events when the target element changes.
     * @property {boolean} hide If True, hide the target in the DOM.
     */
    props: {
        target: {
            type: [jQuery, String, HTMLElement],
            required: true,
        },
        domProps: {
            type: Object,
            default: () => ({}),
        },
        attrs: {
            type: Object,
            default: () => ({}),
        },
        insert: String,
        observe: Boolean,
        hide: Boolean,
    },
    /**
     * @returns {object} TargetElement, targetSelector and isTargetFound - Related to new DOM target.
     */
    data() {
        /**
         * @property {jQuery|null} targetElement The target as a jQuery object.
         * @property {string|null} targetSelector The CSS selector for the target.
         * @property {boolean} isTargetFound Indicator if a valid targetElement has been found.
         */
        return {
            targetElement: null,
            targetSelector: null,
            isTargetFound: false,
            elementObserver: getTargetElementObserver(this),
            storedLazyLoad: null,
        };
    },
    computed: {
        /**
         * @returns {object}
         * @property {Function<Array>} allClasses All of the classes found on this component.
         * @property {Function<object>} allStyles All of the CSS styles found on this component.
         * @property {Function<object>} allAttrs All of the attributes found on this component.
         */
        elementProps() {
            return {
                allClasses: () => union([], [this.$vnode.data.staticClass], this.$vnode.data.class),
                allStyles: () => merge({}, this.$vnode.data.style, this.$vnode.data.staticStyle),
                allAttrs: () => merge({}, this.$vnode.data.attrs, this.attrs),
            };
        },
        /**
         * @returns {object}
         * @property {Function} found A debounced function to emit a "found" event.
         * @property {Function} mutated A debounced function to emit a "mutated" event.
         */
        fireEvent() {
            const app = this;
            return {
                found: debounce(
                    () => {
                        const target = app.getTarget();
                        app.$emit("found", target.element);
                    },
                    50,
                    {
                        trailing: false,
                        leading: true,
                    }
                ),
                mutated: debounce(
                    () => {
                        app.getTarget();
                        app.$emit("mutated", app.targetElement, app);
                    },
                    MUTATION_BUFFER,
                    {
                        leading: false,
                        trailing: true,
                    }
                ),
                removed: debounce(
                    () => {
                        const target = app.getTarget();
                        app.$emit("removed", target.element);
                    },
                    50,
                    {
                        trailing: false,
                        leading: true,
                    }
                ),
            };
        },
    },
    methods: {
        /**
         * Updates the targetElement properties, attributes, classes, styles, etc. With values from this component.
         * @param {'domProps'|'classes'|'styles'|'attrs'|'hide'|undefined} key The type of update to run. Leaving this undefined will run all updates.
         */
        updateElement(key) {
            const updateMap = {
                domProps: () => !isEmpty(this.domProps) && this.targetElement.prop(this.domProps),
                classes: () => {
                    const classes = this.elementProps.allClasses();
                    !isEmpty(classes) && this.targetElement.addClass(classes.join(" "));
                },
                styles: () => {
                    const styles = this.elementProps.allStyles();
                    !isEmpty(styles) && this.targetElement.css(styles);
                },
                attrs: () => {
                    const attrs = this.elementProps.allAttrs();
                    !isEmpty(attrs) && this.targetElement.attr(attrs);
                },
                hide: () => this.hide && this.targetElement.hide(),
            };
            if (this.isTargetFound) {
                if (!key) {
                    this.elementObserver.whilePaused(() => {
                        for (const _key in updateMap) {
                            updateMap[_key]();
                        }
                    });
                } else {
                    this.elementObserver.whilePaused(updateMap[key]);
                }
            }
        },
        /**
         * Finds the target on the page via the targetSelector. Sets:
         * - targetElement
         * - targetSelector
         * - isTargetFound.
         * @returns {object} The target data.
         * @property {string} selector The target CSS selector.
         * @property {jQuery|null} element The target element.
         * @property {boolean} isMutated If True, the target element found does not match the original target.
         * @property {boolean} isFound If True, the target element was found on the page.
         */
        getTarget() {
            const selector =
                typeof this.target === "string"
                    ? this.target
                    : this.$(this.target).selectorPath({ withAttributes: false });
            const element = this.$(selector);
            const isTargetCurrentlyFound = !!element?.length;
            this.targetElement = element;
            this.targetSelector = selector;
            const isRemoved = !isTargetCurrentlyFound && this.isTargetFound;
            const isAdded = isTargetCurrentlyFound && !this.isTargetFound;
            this.isTargetFound = isTargetCurrentlyFound;
            return {
                selector,
                element: this.isTargetFound ? element : null,
                isMutated: this.isTargetFound && !element.is(this.target),
                isFound: this.isTargetFound,
                isRemoved,
                isAdded,
            };
        },
        /**
         * Update the target element if updates have not already been performed.
         * @fires updated - Indicates the target element was updated.
         */
        runUpdates() {
            const target = this.getTarget().element;
            // If the target element has the "dom-overwrite" attribute, updates have already been completed.
            if (!target.attr("dom-overwrite")) {
                this.updateElement();
                target.attr("dom-overwrite", true);
                this.$nextTick(() => {
                    this.$emit("updated", target);
                });
            }
        },
        /**
         * @returns {object} JqueryObserver - Added for the new element in the DOM.
         *
         * Fires the "found" event once the target is present on the page.
         *
         * It does this by creating a MutationObserver on the root <html> element. When a new
         * element is added to the DOM, the observer checks if the new element is the target the
         * DomOverwrite is looking for. Once the target is found, the observer is disconnected.
         */
        lazyLoad() {
            if (!this.storedLazyLoad) {
                const handler = debounce(
                    () => {
                        const target = this.getTarget();
                        if (target.isFound) {
                            this.runUpdates();
                            this.fireEvent.found();
                            this.lazyLoad().stop();
                        }
                    },
                    20,
                    {
                        leading: false,
                        trailing: true,
                    }
                );
                this.storedLazyLoad = new BaseMutationObserver("body", handler, {
                    logger: this.$logger.extend("LazyLoadObserver", { enabled: false }),
                });
            }
            return this.storedLazyLoad;
        },
        /**
         * If insert is True, the HTMLElement corresponding to this component (ie this.$el) is moved
         * to the target via the selected insert mode. This does not trigger any lifecycle hooks.
         */
        insertSlot() {
            const actionMap = {
                prepend: (targetElement) => targetElement.prepend(this.$el),
                append: (targetElement) => targetElement.append(this.$el),
                replace: (targetElement) => targetElement.replaceWith(this.$el),
                before: (targetElement) => targetElement.before(this.$el),
                after: (targetElement) => targetElement.after(this.$el),
                last: (targetElement) => targetElement.parent().append(this.$el),
                first: (targetElement) => targetElement.parent().prepend(this.$el),
            };
            const insert = () => {
                const target = this.getTarget();
                this.elementObserver.whilePaused(() => {
                    actionMap[this.insert.toLowerCase()](target.element);
                });
            };
            if (this.insert) {
                this.$logger.meta(`${this.insert}`);
                insert();
            }
        },
    },
    /**
     * - Add an event handler to insert the slot once the component is updated.
     * - Add watchers to keep classes, styles, attrs, and domProps in-sync.
     */
    created() {
        this.$on("updated", this.insertSlot);
        this.$watch(this.elementProps.allClasses, () => this.updateElement("classes"));
        this.$watch(this.elementProps.allStyles, () => this.updateElement("styles"));
        this.$watch(this.elementProps.allAttrs, () => this.updateElement("attrs"));
        this.$watch("domProps", () => this.updateElement("domProps"));
    },
    // eslint-disable-next-line
    mounted() {
        // Normalize the target into a jQuery element.
        const target = this.getTarget();
        this.$logger.prefix = `${target.selector} `;

        // Execute the mode action if target found.
        if (target.isFound) {
            this.$logger.meta(`Target found on load.`);
            this.runUpdates();
            this.fireEvent.found();
        }

        // If the target cannot be found, wait for the target to be found.
        else {
            this.$logger.meta(`Using LazyLoading`);
            this.lazyLoad().start();
        }
        if (this.observe) {
            this.$logger.meta(`Starting Observer`);
            this.elementObserver.start();
        }
    },
    /**
     * Remove observers, remove inserted elements, and cancel any debounced function calls.
     */
    beforeDestroy() {
        this.$logger.meta("Destroyed", this);
        this.elementObserver.stop();
        this.lazyLoad().stop();
        this.targetElement.removeAttr("dom-overwrite");
        this.fireEvent.found.cancel;
        this.fireEvent.mutated.cancel;
        if (this.insert) {
            this.$(this.$el).remove();
        }
    },
};
</script>

<style></style>
