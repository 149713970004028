import { BaseMutationObserver } from "@/core/utils";
import { debounce } from "lodash";

const metaLog = debounce((component, msg, target) => component.$logger.meta(msg, target), 300);

const callback = (component) => {
    const target = component.getTarget();

    if (target.isMutated) {
        component.fireEvent.mutated();
        metaLog(component, "Mutated", target);
    }
    if (target.isRemoved) {
        component.fireEvent.removed();
        metaLog(component, "Removed", target);
    }
    if (target.isFound && target.isAdded) {
        component.runUpdates();
        component.fireEvent.found();
        metaLog(component, "Found", target);
    }
};

/**
 * The mutation observer for the DomOverwrite target.
 * @param {*} component The DomOverwrite component.
 * @returns {BaseMutationObserver}
 */
export function getTargetElementObserver(component) {
    return new BaseMutationObserver("body", () => callback(component));
}
