/**
 *
 * @param obj
 * @param path
 */
function getDeepValue(obj, path) {
    const parts = path.split(".");
    for (let i = 0, len = parts.length; i < len; i++) {
        obj = obj[parts[i]];
    }
    return obj;
}

/**
 * Returns data stored in browser localStorage by the ReCharge Adapter.
 * @param {string} path The dot-notated path to the key you wish to retrieve.
 * @returns {object|*} The value found at the path provided. If no path is provided, all data is returned.
 */
export function getStoredData(path) {
    const data = JSON.parse(localStorage.getItem("vuex"));
    if (path) {
        return getDeepValue(data, path);
    }
    return data;
}
