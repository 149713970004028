import { App } from "@/bigcommerce/pages";
import store from "@/core/vue/store";
import { RCAInterface } from "@/core/interfaces";
import version from "@config/version.json";
import { AppVue, globalConsoleLogger, initVueOptions } from "@/core/utils";
import { loggerEnabled } from "@/core/utils/logger/loggerUtils";

window.RCAInterface = RCAInterface;

/* eslint-disable no-undef */
try {
    window.RCA_LOCALES = window.RCA_LOCALES || RCA_LOCALES;
} catch (e) {
    window.RCA_LOCALES = { lang: "en", locales: {} };
}
export const clientLocales = window.RCA_LOCALES;

try {
    window.RCA_SETTINGS = window.RCA_SETTINGS || RCA_SETTINGS;
} catch (e) {
    window.RCA_SETTINGS = {};
}

try {
    window.RCA_DATA = window.RCA_DATA || RCA_DATA;
} catch (e) {
    // pass
}
// this comment is used to check feature file

store.commit("version", version);

/* eslint-disable no-undef */
// Wait for RCA_DATA to be defined.
let checkExist = setInterval(function () {
    if (window.RCA_DATA) {
        clearInterval(checkExist);
        initVueOptions({
            pageSettings: window.RCA_SETTINGS,
            storeObjectsData: window.RCA_store_objects,
            storeData: window.RCA_DATA,
            loggerEnabled,
            environment: process.env.NODE_ENV,
        });
        // Prevent the app from loading more than once.
        if (!window.rechargeApp) {
            window.rechargeApp = true; // Set a truthy value asap to limit race conditions.
            globalConsoleLogger.debug(`ReCharge Adapter Version: ${version.ui}`);
            const app = new AppVue({
                render: (h) => h(App),
            });
            app.$mount();
            window.rechargeApp = app;
        }
    }
}, 20);

// Stop checking for RCA_DATA. It either loaded or clearly is not here.
setTimeout(clearInterval, 10000, checkExist);
