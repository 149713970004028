<template>
    <div v-if="shouldLoadWidget">
        <div v-for="product in onPageProducts" :key="product.id">
            <template v-if="product.modifierEl">
                <template v-for="el in product.modifierEl">
                    <dom-overwrite :target="el" insert="last" hide>
                        <subscription-product-widget v-if="enabled" :product="product" />
                    </dom-overwrite>
                </template>
            </template>
            <product-sync v-if="syncIsEnabled" :product="product" />
        </div>
    </div>
</template>

<script>
import SubscriptionProductWidget from "@/core/ProductWidget/SubscriptionProductWidget";
import DomOverwrite from "@/core/vue/components/DomOverwrite/DomOverwrite";
import { BaseMutationObserver, waitForTime } from "@/core/utils";
import { throttle } from "lodash";
import ProductSync from "@/bigcommerce/pages/shared/productWidget/ProductSync";

export default {
    name: "ProductWidget",
    props: {
        enabled: Boolean,
    },
    components: { DomOverwrite, SubscriptionProductWidget, ProductSync },

    // eslint-disable-next-line jsdoc/require-jsdoc
    data() {
        return {
            /**
             * @type {Array<RcaDataProduct>}
             */
            onPageProducts: [],
            observer: new BaseMutationObserver("body", throttle(this.refreshProduct, 50)),
            isEnabled: true,
            syncIsEnabled: false,
        };
    },
    computed: {
        /**
         * @returns {boolean} Returns True if the widget should be loaded on the page.
         */
        shouldLoadWidget() {
            if (this.onPageProducts.length) {
                this.$logger.debug("Loading product widget", {
                    onPageProducts: this.onPageProducts,
                });
                return true;
            }
            this.$logger.debug("No subscription products on page. Not loading product widget.");
            return false;
        },
    },
    methods: {
        /**
         * @returns {Array} This list of all subscription products on the current page.
         */
        getAllSubscriptionModifiers() {
            const allDropdownModifiers = this.$(".form-field[data-product-attribute='set-select']");
            if (!this.$store_data?.subscriptionProducts?.length) {
                return [];
            }
            return this.$store_data.subscriptionProducts
                .map((product) => ({
                    modifierEl: allDropdownModifiers.has(
                        `[name="attribute[${product?.subscriptionModifier?.id}]"]`
                    ),
                    ...product,
                    discountAmount: product?.subscription?.discount_amount ?? 0,
                    subscriptionType:
                        product?.subscription?.storefront_purchase_options ??
                        "subscription_and_onetime",
                }))
                .filter((product) => !!product.modifierEl.length);
        },
        /**
         *
         */
        refreshProduct() {
            const onPageProducts = this.getAllSubscriptionModifiers();
            const originalProductIds = this.onPageProducts.map((product) => product.id);
            const onPageProductIds = onPageProducts.map((product) => product.id);
            const newProductIds = onPageProductIds.filter((id) => !originalProductIds.includes(id));
            const removedProductIds = originalProductIds.filter(
                (id) => !onPageProductIds.includes(id)
            );
            if (newProductIds.length || removedProductIds.length) {
                this.onPageProducts = onPageProducts;
                this.$logger.info(`Subscription Products On Page: ${onPageProductIds.length}`, {
                    added: newProductIds.length,
                    removed: removedProductIds.length,
                    onPageSubscriptionProducts: this.onPageProducts,
                });
            }
            if (!onPageProductIds.length) {
                this.$logger.info("No Subscription Products On Page", {
                    originalProductIds,
                    onPageProductIds,
                    t: this.onPageProducts,
                });
            }
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        this.isEnabled = this.settings?.pages?.product?.enabled ?? true;
        this.syncIsEnabled = this.settings?.pages?.product?.sync_enabled ?? true;
        this.$logger.debug(`Product Sync ${this.syncIsEnabled ? "Enabled" : "Disabled"}`);
        if (this.isEnabled && !this.bigcommerce.currentPageType.isCheckout) {
            this.refreshProduct();
            this.observer.start();
            if (this.bigcommerce.currentPageType.isProduct) {
                // The widget sometimes doesn't load due to it running before the modifier is on the page.
                // This helps mitigate that.
                waitForTime(500).then(this.refreshProduct);
                const productId = this.$store_objects.product.id;
                if (!productId) {
                    this.$logger.warn("No product ID found for current prodict page.");
                    return;
                }
                const rechargeData = this.$store_data.getProductByBCProductID(productId);
                if (!rechargeData) {
                    this.$logger.warn(`No data found for Product ID #${productId}`);
                    return;
                }
                this.$logger.info("Current Product", {
                    bigcommerceData: this.$store_objects.product,
                    rechargeData,
                });
                // If we are on a subscription product page but cannot find the modifier, we need to sync
                // the product. Thus, the current page's product is manually added to the onPageProducts.
                if (!this.onPageProducts.find((product) => product.id === rechargeData.id)) {
                    if (rechargeData.isSubscription) {
                        this.onPageProducts = [...this.onPageProducts, rechargeData];
                        this.$logger.info(
                            "Current Product Subscription Modifier not found. Manually syncing."
                        );
                    }
                }
            }
        }
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    destroyed() {
        this.observer.stop();
    },
};
</script>

<style></style>
