import customClasses from "@/bigcommerce/config/custom-classes.json";
import { CustomClassesMixin } from "@/bigcommerce/vue/mixins";

/**
 *
 */
function getCustomClasses() {
    /**
     * $custom_classes.themes is a list of themes and its correct order, defined in custom_classes.json
     * $custom_classes.groups is the custom_classes.json object.
     * $custom_classes.classes is the modules object as a flat object to facilitate element selection.
     */
    const groups = typeof customClasses !== "undefined" ? { ...customClasses } : {};
    const themes = groups["custom_classes_settings"]["themes"] || [];
    delete groups["custom_classes_settings"];

    // eslint-disable-next-line no-unused-vars
    const classesList = Object.entries(groups).map(([_, classes]) => classes);
    const classes = classesList.reduce((list, classes) => ({ ...list, ...classes }), {});

    return {
        themes,
        groups,
        classes,
    };
}

export const $customClasses = getCustomClasses();

export default {
    /**
     * Custom selectors to append RCA custom classes.
     * @param {Vue} A Vue instance to be used with.
     * @param Vue
     */
    install(Vue) {
        Vue.prototype.$custom_classes = getCustomClasses();

        Vue.mixin(CustomClassesMixin);
    },
};
