<template>
    <span class="rca-subscription-form" v-if="product.isSubscription">
        <label
            class="rca-subscription-form__buttons-type rca-subscription-form__buttons"
            v-if="!product.isSubscriptionOnly"
        >
            <!-- ReCharge Widget Left Button -->
            <span @click="selectedValue = buttonConfig.left.buttonType">
                <single-button
                    :button-type="buttonConfig.left.buttonType"
                    :button-title="buttonConfig.left.buttonTitle"
                    is-left-or-right="left"
                    :is-selected="buttonConfig.left.isSelected"
                ></single-button>
            </span>

            <span @click="selectedValue = buttonConfig.right.buttonType">
                <!-- ReCharge Widget Right Button -->
                <single-button
                    :button-type="buttonConfig.right.buttonType"
                    :button-title="buttonConfig.right.buttonTitle"
                    is-left-or-right="right"
                    :is-selected="buttonConfig.right.isSelected"
                ></single-button>
            </span>
        </label>
        <subscription-form-frequency
            :show="showFrequencyInput || product.isSubscriptionOnly"
            :product="product"
        />
    </span>
</template>

<script>
import SubscriptionDetailsMixin from "@/core/vue/mixins/subscription-details";
import SingleButton from "@/core/ProductWidget/FormTypes/ButtonFormType/SingleButton";
import SubscriptionFormFrequency
    from "../../../vue/components/SubscriptionForm/SubscriptionFormFrequency/SubscriptionFormFrequency.vue";
// noinspection JSValidateTypes
export default {
    name: "ButtonForm",
    components: {
        SingleButton,
        SubscriptionFormFrequency,
    },
    mixins: [SubscriptionDetailsMixin],
    /**
     *@returns {object} The Initialized options of the button form.
     */
    data() {
        return {
            productId: this.product.id,
            buttonForm: {
                subscriptionMsg: this.subscriptionTextConfig(),
            },
        };
    },
    props: {
        /**
         * @type {RcaDataProduct}
         */
        product: Object,
    },
    computed: {
        /**
         * @typedef {object} subscription and onetime
         * @property {object} left - Properties for the first label element.
         * @property {boolean} left.isSelected - Whether the button is selected or not.
         * @property {string} left.buttonType - The value of the input element.
         * @property {string} left.buttonTitle - The text value displayed by the span element.
         * @property {object} right - Properties for the second label element.
         * @property {boolean} right.isSelected - Whether the button is selected or not.
         * @property {string} right.buttonType - The value of the input element.
         * @property {string} right.buttonTitle - The text value displayed by the span element.
         *
         * @returns {optionProps} The first and second elements according to the value.
         */
        buttonConfig() {
            if (!this.buttonForm.subscriptionMsg) {
                this.buttonForm.subscriptionMsg = this.subscriptionTextConfig();
            }
            const subscription = {
                buttonTitle: this.buttonForm?.subscriptionMsg,
                buttonType: "subscription",
                isSelected: this.selectedValue === "subscription",
            };
            const onetime = {
                buttonTitle: this.onetimeMsg,
                buttonType: "onetime",
                isSelected: this.selectedValue === "onetime",
            };
            if (this.isSubscriptionShownFirst) {
                return {left: subscription, right: onetime};
            }
            return {right: subscription, left: onetime};
        },
    },
    methods: {
        /**
         * Adds the correct discount price to the subscription label if there's any percentage discount.
         * @returns {string} The formatted subscription price.
         */
        subscriptionTextConfig() {
            const formatText = this.configDiscountText(this.product.discountAmount);
            return this.subscriptionDisplayText(formatText);
        },
    },

    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        this.$logger.debug("Using product Button Form");
    },
};
</script>

<style>
.rca-subscription-form__buttons-type {
    display: flex;
}

.rca-subscription-form__button {
    cursor: pointer;
    background: none;
    color: var(--font_color);
    display: inline-block;
    min-width: 144px;
    width: 50%;
    height: 100%;
    padding: 0.5em 0.2em;
    margin: 0;
    border-radius: 0;
    border: #917052 0.1em solid;
    text-align: center;
    flex: 1;
    font-size: 1em;
}

/**
* Styling for the button on the left or right respectively. To keep styling and custom classes separated
* and to avoid conflicts when the buttons change places.
*/
.rca-button-left {
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    margin-right: 1px;
}

.rca-subscription-form__button--selected {
    box-shadow: 0 0 0 1px #917052;
    background: var(--background_color);
    color: var(--active_color);
}

.rca-button-right {
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    margin-left: 1px;
}

.rca-subscription-form__button--selected {
    box-shadow: 0 0 0 1px var(--active_color);
}

.rca-subscription-form__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 338px;
}

@media only screen and (max-width: 400px) {
    .rca-subscription-form__button {
        font-size: 13px;
        padding: 0.5em 0.3em;
        width: calc(50vw - 30px);
        padding-top: 9px;
    }
}

@media only screen and (min-width: 401px) {
    .rca-subscription-form__button {
        width: 169px;
    }
}
</style>
