import { WaitForBase } from "@/core/utils/waitFor/waitForBase";

/**
 *
 */
export class WaitForTrue extends WaitForBase {
    /**
     *
     * @param condition
     * @param msToWait
     */
    constructor(condition, msToWait) {
        super();
        this.condition = condition;
        this.msToWait = msToWait;
    }
    /**
     * @returns {Promise}
     */
    start() {
        const success = new Promise((resolve) => this.checkCondition(resolve));
        const failure = new Promise(
            (_, reject) => this.msToWait && setTimeout(reject, this.msToWait, "Timeout")
        );
        return Promise.race([success, failure]);
    }

    /**
     *
     * @param resolve
     */
    async checkCondition(resolve) {
        const result = this.condition();
        if (!result) {
            await this.newTimeoutPromise(10);
            await this.checkCondition(resolve);
        } else {
            resolve(true);
        }
    }
}

/**
 *
 * @param condition
 * @param msToWait
 * @returns {Promise}
 */
export function waitForTrue(condition, msToWait = 0) {
    return new WaitForTrue(condition, msToWait).start();
}
