import { adapterBackendMixin } from "@/core/utils";
import { isEmpty } from "lodash";

export const PaymentProviderMixin = {
    /**
     * Initialization the variables for BraintreePaymentsMixin.
     * @returns {object} All the data initialized.
     */
    mixins: [adapterBackendMixin],
    /**
     *
     */
    data() {
        return {
            /**
             * @type {BraintreeController}
             */
            payment_provider: null,
            name: null,
        };
    },
    methods: {
        /**
         * Alert error modal.
         * @param {"charge_error"|"payment_method_error"|"address_error"|"email_error"|"phone_error"|"generic_error"|"custom"} code The code used to select the correct error message.
         * @param {object} config Config object.
         * @param {string|undefined} config.text Text used for the error message displayed.
         * @param {string|undefined} config.title Text used for the error title displayed.
         * @param {boolean} config.important If True, this error modal will override any error modals already being shown.
         */
        alertErrorModal(code, { text, title, important = false } = {}) {
            if (this.$(".swal2-show.swal2-icon-error").length) {
                this.$logger.warn("Already showing error message");
                if (!important) return;
            }
            const codeMap = {
                charge_error: {
                    title: this.$t("checkout.processing_error_title"),
                    text: this.$t("checkout.processing_error_message"),
                },
                payment_method_error: {
                    title: this.$t("checkout.processing_error_title"),
                    text: this.$t("checkout.processing_error_message"),
                },
                address_error: {
                    title: this.$t("checkout.address_error_title"),
                    text: text ?? this.$t("checkout.address_error_message"),
                },
                email_error: {
                    title: this.$t("checkout.email_error"),
                    text: this.$t("checkout.email_error"),
                },
                phone_error: {
                    title: this.$t("checkout.phone_error_title"),
                    text: this.$t("checkout.phone_error_message"),
                },
                custom: {
                    title: title ?? this.$t("checkout.generic_error_title"),
                    text: text ?? this.$t("checkout.generic_error_message"),
                },
            };
            title = codeMap[code]?.title ?? this.$t("checkout.generic_error_title");
            text = codeMap[code]?.text ?? this.$t("checkout.generic_error_message");

            this.$swal({
                title: title ?? this.$t("checkout.error_title"),
                didClose: () => window.location.reload(),
                icon: "error",
                buttonsStyling: false,
                customClass: {
                    confirmButton: "rca-confirm-button",
                },
                width: "56rem",
                text: text ?? this.checkoutMessage,
            });
        },
        /**
         *
         */
        async getToken() {
            throw `The 'getToken' method is not defined for ${this.name}`;
        },
        /**
         * Converts the current BigCommerce Checkout/Cart into a BigCommerce Order.
         * @returns {object} The BigCommerce Order created.
         */
        async convertCheckoutToOrder() {
            try {
                return await this.bigcommerce.cart.convertToOrder();
            } catch (e) {
                this.alertErrorModal("custom", { text: e?.response?.data?.detail });
                throw new Error(
                    "An error occurred while converting the BigCommerce Checkout/Cart to an Order."
                );
            }
        },
        /**
         * Submits payment nounce to BCI API.
         * @returns {object|null} Response from server.
         */
        async submitPayment() {
            const token = await this.getToken();
            await this.bigcommerce.getUpdatedCart();
            if (!isEmpty(token)) {
                this.$logger.debug(`${this.name} payment tokenized`);
                const isZeroDollarOrder =
                    this.bigcommerce.cart.checkoutData.outstandingBalance === 0;
                const isPartiallyPaid =
                    this.bigcommerce.cart.checkoutData.outstandingBalance <
                    this.bigcommerce.cart.checkoutData.grandTotal;
                const storeCreditApplied =
                    this.bigcommerce.cart.checkoutData?.isStoreCreditApplied || false;
                if (isZeroDollarOrder) {
                    await this.adapterBackend.vaultCheckout(
                        this.storeHash,
                        this.bigcommerce.cart.cartId
                    );
                }
                let customerGroup = this.bigcommerce.cart.checkoutData.customer.customerGroup;
                //in js a empty array is truthy so default this
                if (Array.isArray(customerGroup) && customerGroup.length === 0) {
                    customerGroup = null;
                }

                const order = await this.convertCheckoutToOrder();
                return await this.adapterBackend.processCheckout(this.storeHash, {
                    payment_method: this.name.toLowerCase(),
                    checkoutId: this.bigcommerce.cart.cartId,
                    customerGroup: customerGroup,
                    isDiscountedOrder:
                        isZeroDollarOrder || storeCreditApplied || isPartiallyPaid || false,
                    checkoutIsVaulted: isZeroDollarOrder || false,
                    nonce: token,
                    orderId: order.id,
                    settings: this.settings,
                });
            }
            return null;
        },
        /**
         *
         */
        async start() {
            throw `The 'start' method is not defined for ${this.name}`;
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    async mounted() {
        if (!this.name) {
            throw "This Payment Provider has no name!";
        }
        this.$logger.info(`${this.name} loading...`);
        await this.start();
    },
};
