<template>
    <transition name="slide-fade">
        <label v-if="show" class="rca-subscription-form__frequency"
            >{{ frequencyDisplayLabel }}
            <subscription-frequency-select
                :product="product"
                @updated="debouncedUpdate"
                @hook:mounted="debouncedUpdate"
                @hook:destroyed="debouncedUnselect"
            />
        </label>
    </transition>
</template>

<script>
import SubscriptionFrequencySelect from "./SubscriptionFrequencySelect";
import { selectModifierOption } from "@/core/vue/mixins/subscription-details";
import { debounce } from "lodash";

const selectOption = debounce(selectModifierOption, 30, { leading: false, trailing: true });

export default {
    name: "SubscriptionFormFrequency",
    components: { SubscriptionFrequencySelect },
    props: {
        product: Object,
        /**
         * Whether to hide or show the frequency input.
         */
        show: {
            type: Boolean,
            default: true,
        },
    },
    /**
     *
     */
    data() {
        const app = this;
        return {
            debouncedUpdate: debounce(app.updateDropdownValue, 100, {
                leading: false,
                trailing: true,
            }),
            debouncedUnselect: debounce(app.unselectDropdownFrequency, 100, {
                leading: false,
                trailing: true,
            }),
        };
    },
    computed: {
        /**
         *@returns {string} The frequency label text.
         */
        frequencyDisplayLabel: function () {
            return this.$t("products.widget_deliver_every");
        },
    },
    methods: {
        /**
         *
         */
        unselectDropdownFrequency() {
            this.$logger.debug("Unselect dropdown value");
            selectOption({
                modifierId: this.product?.subscriptionModifier?.id,
                optionId: null,
            });
        },
        /**
         *
         */
        updateDropdownValue() {
            this.$logger.debug("Update dropdown value");
            const modifier = this.product?.subscriptionModifier;
            const selectedOption = this.$(".rca-subscription-form__frequency-option:selected");
            const optionId = selectedOption.length ? selectedOption.attr("option-id") : null;
            selectOption({ modifierId: modifier.id, optionId });
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        this.debouncedUpdate();
    },
};
</script>

<style>
.rca-subscription-form__frequency {
    display: block;
    margin-top: 0.5em;
}
@media only screen and (max-width: 550px) {
    .rca-subscription-form {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}
.rca-subscription-form__frequency-selector {
    width: 200px;
    display: block;
    border-radius: 0.5em;
    padding: 0.5em;
    color: black;
}

.rca-subscription-form__frequency-selector:focus {
    box-shadow: 0 0 0 0.5pt black;
    outline: none !important;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    max-height: 4em;
    transition: all 0.5s ease-in-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>
