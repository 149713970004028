import { Logger } from "./loggerClass";

export let loggerEnabled = !["production"].includes(process.env.NODE_ENV);
if (/[?&](debug|recharge)=(test|true)/i.test(window.location.search)) {
    // This allows us to view logging messages on a production store.
    loggerEnabled = true;
}
export const globalConsoleLogger = new Logger(null, {
    enabled: loggerEnabled,
});
