import LocalizeMixin from "./localize";
import { mapGetters } from "vuex";
import { simulateNewOptionSelected } from "@/core/utils";
import { isEmpty, debounce } from "lodash";
import $ from "@/core/libs/jquery";

import { globalConsoleLogger } from "@/core/utils/logger/loggerUtils";

/**
 *
 * @param modifierId
 * @param optionId
 */

/**
 *
 * @param root0
 * @param root0.modifierId
 * @param root0.optionId
 */
export function selectModifierOption({ modifierId, optionId }) {
    const modifier = $(`select[name="attribute[${modifierId}]"]`);
    const originalValue = modifier.val();
    const optionValue = optionId || "";
    // eslint-disable-next-line eqeqeq
    if (originalValue == optionValue) {
        return;
    }
    // Set the new value on the modifier.
    modifier.val(optionId);
    // We take an "eventual consistency" approach here. We fire the same debounced function at 150ms,
    // 500ms, and 1000ms. It is a trailing debounce so only the last call is used for each type.
    // This means even if the selection is changed rapidly, we only fire the most recent selection.
    // We do this because BigCommerce triggers its price change based input/changed events. BUT, it
    // occasionally reads the previous value rather than the current value. By firing the events at
    // 3 different intervals, we ensure BigCommerce gets at least one event with the current value.
    _simulateNewOptionSelected.shortDebounce(modifierId, optionId);
    _simulateNewOptionSelected.longDebounce(modifierId, optionId);
    _simulateNewOptionSelected.extraLongDebounce(modifierId, optionId);
}

const _simulateNewOptionSelected = {
    func: (modifierId, optionId) => {
        if (!isEmpty(optionId)) {
            globalConsoleLogger.meta("Select with Option ID", { modifierId, optionId });
            simulateNewOptionSelected(`[data-product-attribute-value="${optionId}"]`);
        } else {
            globalConsoleLogger.meta("Empty select.", { modifierId, optionId });
            simulateNewOptionSelected(`select[name="attribute[${modifierId}]"] option:first-child`);
        }
    },
};
_simulateNewOptionSelected.shortDebounce = debounce(_simulateNewOptionSelected.func, 150, {
    leading: false,
    trailing: true,
});
_simulateNewOptionSelected.longDebounce = debounce(_simulateNewOptionSelected.func, 500, {
    leading: false,
    trailing: true,
});
_simulateNewOptionSelected.extraLongDebounce = debounce(_simulateNewOptionSelected.func, 1000, {
    leading: false,
    trailing: true,
});

export default {
    mixins: [LocalizeMixin],
    computed: {
        ...mapGetters({ subscriptionData: "currentSubData" }),
        /**
         * Determines whether the frequency input element should be displayed.
         *
         * @returns {boolean} Whether to display the input element.
         */
        showFrequencyInput: function () {
            return this.selectedValue === "subscription";
        },
        /**
         * @type {boolean} If True, the subscription button is listed first.
         */
        isSubscriptionShownFirst() {
            const firstOption = this.settings?.pages?.product?.first_option ?? "autodeliver";
            return firstOption.toLowerCase() === "autodeliver";
        },
    },
    /**
     * @returns {object} Initialized data for the subscription widget.
     */
    data() {
        return {
            /**
             * @type {string} The value to bind selections to.
             */
            selectedValue: "onetime",
            /**
             * @type {string} The text to display for the onetime option.
             */
            onetimeMsg: this.$t("products.onetime_message"),
        };
    },
    methods: {
        /**
         * Updates the selected button by getting the value of the button clicked.
         * @param {string} selectedButtonType The value of the selected button.
         */
        toggleSelection(selectedButtonType) {
            this.selectedValue = selectedButtonType;
        },
        /**
         * @param {string} subscriptionType  The type of subscription the product has.
         * @returns {boolean} Indicator if this product should show Subscription/Onetime selectors.
         */
        showSubscription(subscriptionType) {
            return ["subscription_and_onetime"].includes(subscriptionType);
        },
        /**
         * Adds the percentage sign to the discount amount.
         * @param {number} discountAmount The quantity that is going to be discounted for a subscription product.
         * @returns {string} The formated discount text and an empty string if 0.
         */
        configDiscountText(discountAmount) {
            return discountAmount > 0 ? `${discountAmount}%` : "";
        },
        /**
         * Display formatted text for subscription purchase.
         * @param {string} discountText The amount discounted from a subscription.
         * @returns {string} The formatted text for subscription.
         * Will show Subscribe & save when there's a discount, and subscribe when there isn't.
         */
        subscriptionDisplayText(discountText) {
            return discountText !== ""
                ? `${this.$t("products.subscribe_message")} ${discountText}`
                : this.$t("products.subscribe_without_discount_message");
        },
        /**
         * Display formatted text for any purchase.
         *  @param {string} discountText The amount discounted from a subscription.
         * @returns {string} The formatted text for any purchase. Mainly used for checkbox form types.
         */
        combinedDisplayText(discountText) {
            return discountText === ""
                ? `<b>${this.subscriptionDisplayText(discountText)}</b>`
                : `<b>${this.subscriptionDisplayText(discountText)}</b> ${this.$t(
                      "products.sub_and_save_ext_label"
                  )}`;
        },
        /**
         *
         * @param {number} productId The ID of the current product.
         * @returns {number} The value of the first frequency on the dropdown.
         */
        selectedFrequencyConfig(productId) {
            return Object.values(
                this.$store_data?.getProductByBCProductID(productId)?.subscriptionModifier
                    ?.frequencies
            )[0];
        },
        /**
         * Returns the unit dictionaries for searches.
         * @returns {object} A dictionary containing different time units with its respective translations.
         */
        frequencyUnitTranslations() {
            try {
                return JSON.parse(this.$t("products.shipping_interval_unit_type"));
            } catch {
                return JSON.parse(JSON.stringify(this.$t("products.shipping_interval_unit_type")));
            }
        },
        /**
         * Goes over each subscription delivery frequency and formats it correctly.
         * @param  {number} productId The current product ID.
         * @returns {object} An object of dicitionaries containing the Value and frequency of the frequency options.
         */
        createFrequencyInformation(productId) {
            const subscription_data = this.$store_data?.getSubscriptionsByBCProductID(productId);
            // Build subscription frequency values to pass to the form.
            const frequencies = subscription_data?.order_interval_frequency_options;
            // Key for get the localization frequency
            const sub_freq_ops = [];
            if (Array.isArray(frequencies) && frequencies.length) {
                let unit;
                frequencies.forEach((freq) => {
                    let unitKey = subscription_data?.order_interval_unit;
                    if (parseInt(freq) > 1) {
                        unitKey = `${unitKey}s`;
                    }
                    unit = this.frequencyUnitTranslations();
                    sub_freq_ops.push({ value: freq, text: `${freq} ${unit[unitKey]}` });
                });
            }
            return sub_freq_ops;
        },
        /**
         * Updates the value of the hidden dropdown according to the value selected.
         * @param {number} productId The ID of the current product.
         * @param {string|number} selectedFrequency The value of the selected frequency.
         */
        updateDropdownValue(productId, selectedFrequency) {
            const dropdownSelector =
                this.$store_data?.getProductByBCProductID(productId)?.subscriptionModifier;
            const subscriptionSelector = this.$(`select[name="attribute[${dropdownSelector.id}]"]`);
            if (this.selectedValue === "subscription") {
                const findByValue = Object.keys(dropdownSelector.frequencies).find(
                    (key) => dropdownSelector.frequencies[key] === parseInt(selectedFrequency)
                );
                subscriptionSelector.val(findByValue);

                simulateNewOptionSelected('[data-product-attribute-value="' + findByValue + '"]');
            } else {
                //if not a subscription, we set the value to an empty string and select the first item on the dropdown.
                subscriptionSelector.val("");
                simulateNewOptionSelected(
                    `select[name="attribute[${dropdownSelector.id}]"] option:first-child`
                );
            }
        },
        /**
         * Check if the subscription options is from a prepaid product.
         *
         * Prepaid products match criteria.
         * Subscription type is subscription only.
         * Subscription order interval exist and only exist one interval.
         * Subscription charge interval exist.
         * Subscription charge interval isn't divisible by order interval.
         *
         * @param {object} subscriptionOptions Product subscription options.
         * @returns {boolean} Bool if subscription options are from a prepaid subscription.
         */
        isPrepaidSubscriptionOptions(subscriptionOptions) {
            const frequency = parseInt(subscriptionOptions.order_interval_frequency_options[0], 10);
            return (
                subscriptionOptions.storefront_purchase_options === "subscription_only" &&
                subscriptionOptions.order_interval_frequency_options?.length === 1 &&
                subscriptionOptions?.charge_interval_frequency > frequency &&
                subscriptionOptions?.charge_interval_frequency % frequency === 0
            );
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        this.selectedValue = this.settings?.pages?.product?.select_subscription_first
            ? "subscription"
            : "onetime";
    },
};
