<template>
    <span class="rca-subscription-form" v-if="product.isSubscription">
        <span class="rca-subscription-form__radio-type" v-if="!product.isSubscriptionOnly">
            <span @click="toggleSelection(radioConfig.first.radioType)">
                <single-radio-option
                    v-model="selectedValue"
                    :is-selected="radioConfig.first.isSelected"
                    :radio-type="radioConfig.first.radioType"
                    :radio-label="radioConfig.first.radioTitle"
                    :subscription-type="subscriptionType"
                ></single-radio-option>
            </span>
            <br />
            <span @click="toggleSelection(radioConfig.second.radioType)">
                <single-radio-option
                    v-model="selectedValue"
                    :is-selected="radioConfig.second.isSelected"
                    :radio-type="radioConfig.second.radioType"
                    :radio-label="radioConfig.second.radioTitle"
                    :subscription-type="subscriptionType"
                ></single-radio-option>
            </span>
        </span>
        <subscription-form-frequency
            :show="showFrequencyInput || product.isSubscriptionOnly"
            :product="product"
        />
    </span>
</template>

<script>
import SubscriptionDetailsMixin from "@/core/vue/mixins/subscription-details";
import SubscriptionFormFrequency from "../../../vue/components/SubscriptionForm/SubscriptionFormFrequency/SubscriptionFormFrequency.vue";
import SingleRadioOption from "@/core/ProductWidget/FormTypes/RadioFormType/SingleRadioOption";

// noinspection JSValidateTypes
export default {
    name: "RadioForm",
    components: {
        SingleRadioOption,
        SubscriptionFormFrequency,
    },
    mixins: [SubscriptionDetailsMixin],
    props: {
        /**
         * @type {RcaDataProduct}
         */
        product: Object,
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    data() {
        return {
            /**
             * @type {string}
             */
            subscriptionType: this.product.subscription.storefront_purchase_options,
            productId: this.product.id,
            discountAmount: this.product.discount_amount,
            radioForm: {
                /**
                 * @type {string} The text to display for the subscription option.
                 */
                subscriptionMsg: this.subscriptionTextConfig(),
            },
        };
    },
    computed: {
        /**
         * @typedef {object} radioForm
         * @property {object} first - Properties for the first label element.
         * @property {string} first.radioType - The value of the radio item.
         * @property {string} first.isSelected - True if this radio item is selected.
         * @property {string} first.radioTitle - The text value displayed by the radio element.
         * @property {object} second - Properties for the second label element.
         * @property {string} second.radioType - The value of the radio item.
         * @property {string} second.isSelected - True if this radio item is selected.
         * @property {string} second.radioTitle - The text value displayed by the radio element.
         *
         * @returns {radioForm} The first and second elements according to the value.
         */
        radioConfig() {
            if (!this.radioForm.subscriptionMsg) {
                this.radioForm.subscriptionMsg = this.subscriptionTextConfig();
            }
            const subscription = {
                radioTitle: this.radioForm.subscriptionMsg,
                radioType: "subscription",
                isSelected: this.selectedValue === "subscription",
            };
            const onetime = {
                radioTitle: this.onetimeMsg,
                radioType: "onetime",
                isSelected: this.selectedValue === "onetime",
            };
            return this.isSubscriptionShownFirst
                ? { first: subscription, second: onetime }
                : { first: onetime, second: subscription };
        },
    },
    methods: {
        /**
         * Formats the text for the subscription button. Adding the expected discounted percentage of the product.
         * @returns {string} The text on the subscription option.
         */
        subscriptionTextConfig() {
            const formatText = this.configDiscountText(this.product.discountAmount);
            return this.subscriptionDisplayText(formatText);
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    mounted() {
        this.$logger.debug("Using product Radio Form");
    },
};
</script>

<style>
.rca-subscription-form__radio {
    margin-right: 0.5em;
}

.rca-subscription-form__label {
    color: var(--font_color);
}

.rca-subscription-form__radio--selected {
    background: var(--background_color);
    color: var(--active_color);
}
</style>
