import { clone } from "lodash";

const START = Date.now();

const logCss = {
    meta: "color: gray; font-weight: normal;",
    debug: "color: #788BFF;font-weight: bold;",
    info: "color: yellow; background: black; font-weight: bold;",
    current: "color: chartreuse; background: black; font-weight: bold;",
    warning: "color: orange; font-weight: normal;",
    error: "color: red; font-weight: normal;",
    highlight: "background: palegreen; color: black; font-weight: bold;",
};

const prefixCss = {
    time: "color: coral; font-weight: normal;",
    location: "color: gray; font-weight: normal;",
    highlight: "background: palegreen; color: black; font-weight: normal;",
};

const defaultEnabled = !["production"].includes(process.env.NODE_ENV);

/**
 *
 */
export class Logger {
    /**
     * @param {string|null} moduleName - The name of the module to be logged.
     * @param {object} [options] - An options object to pass to the logger instance.
     * @param {Console|Logger} [options.logger=console] - The logger object to pass messages to.
     * @param {boolean} [options.enabled=true] - Indicator if this logger object should be enabled
     * and print messages.
     * @param {boolean} [options.formatMessages=true] - Indicator if the logger should create
     * formatted messages.
     */
    constructor(
        moduleName = "Default",
        { logger = console, enabled = defaultEnabled, formatMessages = true } = {}
    ) {
        this.moduleName = moduleName;
        this.enabled = enabled;
        this.logger = logger;
        this._formatMessages = formatMessages;
        this.prefix = "";
        this.cssOverride = "";
        this.highlight = false; // If True, all loging messages are formatted
    }

    /**
     * @param {string} moduleName Name of the module.
     * @param {object} config Update logger configuration.
     * @param {boolean|null} config.enabled - Indicator if this logger object should be enabled
     * and print messages.
     * @returns {Logger} The newly created Logger.
     */
    extend(moduleName, { enabled = this.enabled } = {}) {
        const name = this.methodName ? `${this.moduleName}.${moduleName}` : moduleName;
        return new Logger(name, {
            logger: this.logger,
            enabled: enabled,
            formatMessages: this._formatMessages,
        });
    }

    /**
     * @returns {string} The name of the method or function calling the logger.
     */
    get methodName() {
        let error;

        try {
            throw new Error("");
        } catch (e) {
            error = e;
        }
        // IE9 does not have .stack property
        if (error.stack === undefined) {
            return "";
        }
        /**
         * @type {Array}
         */
        const fullStackList = error.stack.split("\n");
        const stackList = fullStackList
            .filter((stack) => stack.includes("src") || stack.includes("test"))
            .filter((stack) => !stack.includes("Logger"))
            .filter((stack) => !stack.includes("_callee"));
        let stackTrace = stackList.reverse()[0];
        if (/ /.test(stackTrace)) {
            stackTrace = stackTrace.trim().split(" ")[1];
        }
        if (stackTrace && stackTrace.indexOf(".") > -1) {
            stackTrace = stackTrace.split(".")[1];
        }
        if (stackTrace === "render") {
            return (
                fullStackList
                    .find((stack) => stack.includes("VueComponent.computedGetter"))
                    .match("\\[as (.+)\\]")?.[1] || "computed"
            );
        }
        return stackTrace;
    }

    /**
     * @param {boolean} [includeMethod=true] - Indicator if the method name should be included with
     * the formatted message.
     * @returns {string} The logging message formatted with the module name and, optionally, the
     * method name.
     */
    getMetaPrefix(includeMethod = true) {
        let method = "";
        const methodName =
            typeof this._methodName !== "undefined" ? this._methodName : this.methodName;
        if (includeMethod && methodName && methodName !== "eval") {
            method = ` -> ${methodName}`;
        }
        const name = this.moduleName ?? "";
        const time = `+${Date.now() - START}ms`;
        return `%c[${time}]%c[${name}${method}]`;
    }

    /**
     * @param {string} level The severity level of the loffer to return.
     * @param {string|null} cssOverride CSS override values to use with the logger.
     * @returns {Array} The logging message formatted with the module name and, optionally, the
     * method name.
     */
    formatMessage(level, cssOverride = "") {
        let css = cssOverride || this.cssOverride || "";
        if (!css.includes(":")) {
            if (css === "") {
                css = logCss[level] || logCss.meta;
            } else {
                css = logCss[css] || logCss.meta;
            }
        }
        let prefix = `${this.getMetaPrefix(true)}%c`;
        if (!this._isDir) {
            prefix += " %s";
        }
        return [
            prefix,
            prefixCss.time,
            this.highlight ? prefixCss.highlight : prefixCss.location,
            css,
        ];
    }

    /**
     * Returns a logger Function to use with logging a message.
     * @param {string} level The severity level of the loffer to return.
     * @param {string|null} cssOverride CSS override values to use with the logger.
     * @returns {Function} The logger function.
     */
    _getLogMethod(level, cssOverride = null) {
        if (this.highlight) {
            cssOverride = "highlight";
        }
        if (this.enabled) {
            const args = this.formatMessage(level, cssOverride);
            return this.logger[level]?.bind?.(null, ...args);
        }
        return (msg) => msg;
    }

    /**
     * @param {string} color Overrides the logged message text color.
     * @returns {Logger}
     */
    color(color) {
        const logger = clone(this);
        logger.cssOverride = `color: ${color};`;
        return logger;
    }

    /**
     * @param {string} calleeName Overrides the method name for the logged message.
     * @returns {Logger}
     */
    callee(calleeName) {
        const logger = clone(this);
        logger._methodName = calleeName;
        return logger;
    }

    /**
     * Create a log message at the META (below debug) level.
     * @returns {Function}
     */
    get meta() {
        return this._getLogMethod("debug", "meta");
    }

    /**
     * Create a log message at the LOG level.
     * @returns {Function}
     */
    get log() {
        return this._getLogMethod("log");
    }

    /**
     * Create a log message at the DEBUG level.
     * @returns {Function}
     */
    get debug() {
        return this._getLogMethod("debug");
    }

    /**
     * Create a log message at the INFO level.
     * @returns {Function}
     */
    get info() {
        return this._getLogMethod("info");
    }

    /**
     * Create a log message at the INFO level with emphasized styling and trace-stack.
     * This should only be used for temporary logging messages while developing.
     * @returns {Function}
     */
    get current() {
        return this._getLogMethod("info", "current");
    }

    /**
     * Create a log message at the WARN level.
     * @returns {Function}
     */
    get warn() {
        return this._getLogMethod("warn");
    }

    /**
     * Create a log message at the ERROR level.
     * @returns {Function}
     */
    get error() {
        return this._getLogMethod("error");
    }

    /**
     * Create a log message at the FATAL level.
     * @returns {Function}
     */
    get fatal() {
        return this._getLogMethod("fatal");
    }

    /**
     * Set the logger to expect an object for its next message.
     * @returns {Logger}
     */
    get dir() {
        const logger = clone(this);
        logger._isDir = true;
        return logger;
    }

    /**
     * Create a collapsable group where logs or other groups can be attached.
     * @param {string} label - The title of the logging group.
     * @param {boolean} [collapsed=true] - Indicator if the logging group should be displayed as
     * already collapsed.
     * @param {string} level - The logging level of the message.
     * @returns {console.group|Logger} - The newly created group.
     */
    startGroup(label = "", collapsed = true, level = "debug") {
        if (this.enabled) {
            const cmd = collapsed ? "groupCollapsed" : "group";
            this.logger[cmd](...this.formatMessage(level), label);
            const newLogger = clone(this);
            newLogger._formatMessages = false;
            return newLogger;
        }
        return this;
    }

    /**
     * Closes the lowest level open logging group.
     */
    endGroup() {
        if (this.enabled) {
            this.logger.groupEnd();
        }
    }

    /**
     * Checks the value provided. If truthy, the ifTrue message is logged. If falsey, the ifFalse message is logged.
     * @param {*} value The value to check.
     * @param {object} root0 Root.
     * @param {string|null} root0.ifTrue Truthy message to log.
     * @param {string|null} root0.ifFalse Falsey message to log.
     * @param {"debug"|"info"|"current"} root0.level The level of the logged message.
     * @param {object|undefined} root0.extra Extra data to include with the logging message.
     * @returns {boolean}
     */
    checkAndLog(value, { ifTrue = null, ifFalse = null, level = "debug", extra = "" } = {}) {
        if (value) {
            this[level](ifTrue, extra);
            return true;
        }
        this[level](ifFalse, extra);
        return false;
    }
}
