<!-- This component copies an existing elements/node in the DOM and converts it into a Vue
 component. Does not overwrite the cloned element.-->
<template>
    <div v-if="!this.bareElement">
        <slot name="before"></slot>
        <component v-if="template" ref="self" :is="template"></component>
        <slot name="after"></slot>
    </div>

    <component v-else-if="this.bareElement && template" ref="self" :is="template"></component>
</template>

<script>
import { nanoid } from "nanoid";
import jQuery from "jquery-slim";
import { waitForTrue } from "@/core/utils";

/**
 * @property {jQuery} $self This component $el as a jQuery object.
 */
export default {
    name: "Clone",
    props: {
        // Show bare element
        bareElement: {
            type: Boolean,
            default: false,
        },
        // Element to clone
        target: {
            type: [jQuery, String, HTMLElement],
            required: true,
        },
        // Add or replace the cloned element `id` with this value.
        id: {
            type: String,
            default: null,
        },
        // Array of classes to add to the new cloned element.
        addClasses: {
            type: Array,
            default: null,
        },
    },
    // eslint-disable-next-line jsdoc/require-returns
    /**
     * @property {string} rca_id - The unique ID for the temporary element created.
     * @property {object} targetElement - The target as a jQuery object.
     */
    data() {
        const rca_id = nanoid();
        return {
            rca_id,
            targetElement: {},
            template: null,
        };
    },
    methods: {
        /**
         * Assign jQuery representation of target to targetElement.
         */
        handleTargetFoundListener() {
            this.$logger.meta("Target", this.target);
            this.targetElement = this.$(document).find(this.target);
            this.$logger.meta("Clone target found.");
            this.getTemplate();
        },
        /**
         * Sets the template object containing the HTML for the cloned element.
         */
        getTemplate() {
            const app = this;
            this.$logger.meta("Target Element", this.targetElement);
            if (this.targetElement.length) {
                this.targetElement.attr("rca_id", this.rca_id);
                if (this.id) {
                    this.targetElement.attr("id", this.id);
                }
                if (this.addClasses) {
                    this.targetElement.addClass(this.addClasses);
                }
                this.template = {
                    template: app.targetElement.prop("outerHTML"),
                    /**
                     *
                     */
                    mounted() {
                        for (const event in app.$listeners) {
                            app.$el.addEventListener(event, app.$listeners[event]);
                        }
                    },
                };
                this.$logger.meta("Template", this.template);
            }
        },
    },
    /**
     *
     */
    created() {
        Object.defineProperty(this, "$self", { get: () => this.$(this.$el) });
    },
    /**
     *
     */
    mounted() {
        waitForTrue(() => !!this.$(document).find(this.target).length, { throwing: false }).then(
            this.handleTargetFoundListener
        );
    },
};
</script>

<style></style>
