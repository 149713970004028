import _CustomClasses from "@/bigcommerce/config/custom-classes.json";
import { omit } from "lodash";

/**
 * Take an object and return a list of the values.
 * @param {object} obj The object to flatten.
 * @returns {Array}
 */
function objectToFlatList(obj) {
    if (!obj) {
        return [];
    }
    return Object.values(obj)?.flat?.() ?? [];
}

/**
 * @returns {Array} All custom classes as a list.
 */
function customClassesAsList() {
    const customClassesObj = omit(_CustomClasses, "custom_classes_settings");
    const customClassesList = [];
    Object.entries(customClassesObj).forEach(([groupId, record]) => {
        Object.entries(record).forEach(([recordId, data]) => {
            customClassesList.push({
                groupId,
                recordId,
                selectors: objectToFlatList(data.selectors),
                customClass: data.custom_class,
                mode: data.mode ?? "multi",
                mutations: objectToFlatList(data.mutations),
            });
        });
    });
    return customClassesList;
}

/**
 *
 */
class CustomClassesHelper {
    themes = _CustomClasses.custom_classes_settings.themes;
    records = customClassesAsList();

    /**
     * Gets a single custom class record by ID.
     * @param {string} recordId The ID of the custom class record to get.
     * @returns {object} The custom class record.
     */
    getCustomClassRecord(recordId) {
        return this.records.find((record) => record.recordId === recordId);
    }
    /**
     * Get all the selectors for a custom class record.
     * @param {string} recordId The ID of the custom class record to get.
     * @param {object} options Options.
     * @param {object} options.replacements Replace all instances of the object "keys" with their matching "values".
     * @param {boolean} options.asString If true, return the selector list as a comma-separated string.
     * @returns {Array|string} The list of selectors.
     */
    getSelectors(recordId, { replacements = {}, asString = false } = {}) {
        let selectors = this.getCustomClassRecord(recordId).selectors;
        Object.entries(replacements).forEach((key, value) => {
            selectors = selectors.map((singleSelector) =>
                singleSelector.replace(`\${${key}}`, value)
            );
        });
        if (asString) {
            return selectors.join(", ");
        }
        return selectors;
    }
}

export const customClasses = new CustomClassesHelper();
