/**
 * @module This module is used for any helper functions or classes related to interacting with the
 * BigCommerce storefront.
 */

import { buildRequest, AdapterBackendHelper } from "@/core/utils";

/**
 * @type {string} Default store host name.
 */
const _windowOrigin = window.location.origin;

/**
 * @name subscriptionDataGetter
 * @function
 * @summary This type of function should get subscription data from storage and return it.
 * @returns {Array} An Array of subscription data from Vuex storage.
 */

/**
 * @typedef {object} StorefrontCustomer
 * @property {number} id - Unique numeric ID of the customer.
 * @property {string} email - Email address of the customer.
 * @property {string} group_id - The group to which the customer belongs.
 *
 * @typedef {object} getCurrentCustomerResponse
 * @property {StorefrontCustomer} customer - The Storefront Customer object.
 * @property {string} iss - Indicates the token’s issuer. This is your application’s client ID, which is obtained during application registration in Developer Portal.
 * @property {string} sub - The subject of the JWT - same as `store_hash`.
 * @property {number} iat - Time when the token was generated. This is a numeric value indicating the number of seconds since the Unix epoch.
 * @property {number} exp - Time when the token expires. The token usually expires after 15 minutes. This is a numeric value indicating the number of seconds since the Unix epoch.
 * @property {number} version - Version of the Current Customer JWT.
 * @property {string} aud - The “aud” (audience) claim identifies the recipients that the JWT is intended for. This should match the App Client ID and the `application_id`.
 * @property {string} application_id - The client ID created when the token was generated.
 * @property {string} store_hash - Store hash identifying the store you are logging into.
 * @property {string} operation - Must contain the string “current_customer”.
 */

/**
 *
 */
export class BigcommerceCustomer {
    /**
     * @param {object} [obj] - Anonymous object for constructor.
     * @param {string|null} [obj.storeURL] - The full url of the store without a path.
     * @param {string|null} [obj.app_client_id] - The Client ID of the app making the customer call.
     */
    constructor({ storeURL = _windowOrigin, app_client_id = null }) {
        this.storeURL = storeURL;
        this.app_client_id = app_client_id;
    }

    /**
     * A request client for interacting with the Bigcommerce store.
     *
     * @readonly
     * @returns {buildRequest}
     */
    get client() {
        const baseURL = this.storeURL;
        return new buildRequest({ baseURL });
    }

    /**
     *
     */
    create() {}

    /**
     * @typedef { AdapterBackendHelper.adapterCustomer} adapterCustomer
     *
     * @param {string|null} app_client_id - The Client ID of the app making the customer call.
     * @returns {adapterCustomer|null}
     */
    async get_current(app_client_id = null) {
        const request = this.client.request({
            url: "/customer/current.jwt",
            baseURL: this.storeURL,
            params: { app_client_id: app_client_id || this.app_client_id },
        });
        try {
            const response = await request.send();
            const token = response.token;
            const helper = new AdapterBackendHelper();
            return await helper.getAdapterCustomer(token);
        } catch (e) {
            return null;
        }
    }
}
