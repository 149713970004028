import axios from "axios";

import store from "@/core/vue/store";
import { decodeHtmlEntity } from "@/core/utils";

/**
 * The `getRechargeSettings` is used by the `RCAInterface` to get ReCharge Widget Settings.
 * @returns {object} Returns the ReCharge Widget Settings.
 */
async function getRechargeSettings() {
    const storeDomain = RCA_DATA.getStoreDomain();
    const envName = store.getters.isStaging ? ".stage" : "";
    const timestamp = new Date().getTime();
    const url = `https://static${envName}.rechargecdn.com/store/${storeDomain}/2020-12/widget_settings.json?_ts=${timestamp}`;
    const response = await axios.get(url);
    const data = await response.data;
    const widget_settings = decodeHtmlEntity(data.widget_settings || {});
    console.info({ widget_settings });
    return widget_settings;
}

/**
 * @param customerId
 * @param $logger
 */
function getRechargeTempToken(customerId, $logger) {
    const storeHash = RCA_DATA.getStoreHash();
    const url = `${process.env.ADAPTER_API_URL}/recharge/customers/${customerId}/portal_url?store_hash=${storeHash}`;

    return axios
        .get(url)
        .then(({ data, status }) => {
            if (status >= 400) return Promise.reject(data);
            return Promise.resolve(data);
        })
        .catch((err) => $logger.error(`Failed to load Recharge Portal data: ${err}`));
}

export default {
    getRechargeSettings,
    getRechargeTempToken,
};
