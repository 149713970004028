<template>
    <select
        :value="value"
        :ref="`frequencies-${product.id}`"
        v-model="value"
        class="rca-subscription-form__frequency-selector"
    >
        <option
            v-for="(opt, index) in frequencies"
            :value="opt.value"
            :option-id="opt.optionId"
            v-bind:key="index"
            class="rca-subscription-form__frequency-option"
        >
            {{ opt.text }}
        </option>
    </select>
</template>

<script>
export default {
    name: "SubscriptionFrequencySelect",
    props: {
        product: Object,
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    data() {
        return {
            /**
             * @type {string|number|null} The value to bind selections to.
             */
            value: null,
        };
    },
    computed: {
        /**
         * @returns {object} A map linking frequency units to local translations of those units.
         */
        frequencyUnitMap() {
            const shipping_interval_unit_type = this.$t("products.shipping_interval_unit_type");
            if (typeof shipping_interval_unit_type == "string") {
                return JSON.parse(shipping_interval_unit_type);
            }
            return shipping_interval_unit_type;
        },
        /**
         * @returns {Array<object>} Subscription frequencies for the current product.
         */
        frequencies() {
            // Build subscription frequency values to pass to the form.
            const frequencies = Object.entries(
                this.product?.subscriptionModifier?.frequencies ?? {}
            );
            const frequency_unit_key = this.product?.subscription?.order_interval_unit;
            const single_frequency_unit = this.frequencyUnitMap[frequency_unit_key];
            const multiple_frequency_unit = this.frequencyUnitMap[`${frequency_unit_key}s`];
            // Key for get the localization frequency
            return frequencies
                .map(([optionId, value]) => {
                    const unit =
                        parseInt(value) > 1 ? multiple_frequency_unit : single_frequency_unit;
                    return { value, optionId, text: `${value} ${unit}` };
                })
                .filter(
                    // We only want to display frequencies that have matching modifier options.
                    // Otherwise, a customer may try to purchase a non-existent frequency.
                    (freq) => !!this.product.modifierEl.has(`[value="${freq.optionId}"]`).length
                );
        },
    },
    watch: {
        // eslint-disable-next-line jsdoc/require-jsdoc
        value() {
            this.$emit("updated", this.value);
        },
    },
    // eslint-disable-next-line jsdoc/require-jsdoc
    async mounted() {
        if (!this.value) {
            this.value = this.frequencies[0].value;
            await this.$nextTick();
        }
        this.$emit("updated", this.value);
    },
};
</script>

<style></style>
