import { buildRequest } from "@/core/utils";
import store from "@/core/vue/store";
import $ from "@/core/libs/jquery";
import { Logger } from "@/core/utils/logger/loggerClass";

/**
 * @typedef BigcommerceCartCore The core object used within the BigcommerceCart class. The easiest
 * way to get this object is to pass in the root Vue component. Alternatively, custom implementations
 * of this core can be created.
 */

const $logger = new Logger("BigcommerceCart");

export const NeedToQueryCheckoutData = new Error(
    "The BigCommerce Checkout has not been queried yet. Please query the checkout data and try again."
);
const EMPTY = {};

let checkoutQueryPromise = null;

/**
 * @class
 * @classdesc A class for interacting with the BigCommerce cart.
 */
export class BigcommerceCart {
    /**
     * @class
     * @param {object} [obj] - Anonymous object for constructing the object.
     * @param {AppVue|BigcommerceCartCore} obj.core - Core.
     * @param {Logger} obj.logger - Logger object to use.
     * Subscription data should be updated for the cart.
     */
    constructor({ core = {}, logger = $logger }) {
        this.storeURL = core.settings.bigcommerce.store_domain;
        this.rechargeDomain = core.recharge.domain || window.location.hostname;
        this.cartId = core.$store_objects.cart_id || null;
        this._checkoutData = EMPTY;
        this.rcaProductData = core.$store_data.RCA_PRODUCT_DATA;
        this.$logger = logger;
        this._subscriptionDataGetter = () => core.$store.getters.subData;
        this.allCheckoutsOnRecharge = core.settings.backend.all_checkouts_on_recharge;
        this.$store = store;
        if (this.hasStoredCheckout) {
            this._checkoutData = this.storedCheckout;
        }
        this.hasCheckoutData = this._checkoutData !== EMPTY;
    }

    /**
     *
     */
    get storedCheckout() {
        return this.$store.getters["cart/getCheckout"];
    }

    /**
     *
     */
    get hasStoredCheckout() {
        const checkout = this.$store.getters["cart/getCheckout"];
        return !!checkout?.updatedTime && checkout?.cart?.id === this.cartId;
    }

    /**
     * @returns {object} The BigCommerce Checkout Data.
     */
    get checkoutData() {
        if (this._checkoutData === EMPTY) {
            throw NeedToQueryCheckoutData;
        }
        return this._checkoutData || {};
    }

    // eslint-disable-next-line jsdoc/require-jsdoc
    set checkoutData(value) {
        // noinspection JSUnusedGlobalSymbols
        this._checkoutData = value || {};
        store.commit("cart/setCheckout", value);
        this.hasCheckoutData = true;
    }

    /**
     * BigCommerce cart data from the Storefront API.
     *
     * @readonly
     * @returns {null | undefined | object}
     */
    get cartData() {
        return this.checkoutData.cart;
    }

    /**
     * A request client for interacting with the Bigcommerce store.
     *
     * @readonly
     * @returns {buildRequest}
     */
    get client() {
        const baseURL = this.storeURL;
        return new buildRequest({ baseURL });
    }

    /**
     * This gets all data in the cart.
     *
     * @readonly
     * @returns {object[]}
     */
    get subscriptionData() {
        return this._subscriptionDataGetter() || [];
    }

    /**
     * All BigCommerce Cart line items as a flat array.
     *
     * @readonly
     * @returns {Array}
     */
    get allLineItems() {
        const items = [];
        const lineItemSubscriptionData = (subscription) => {
            return {
                ...subscription,
                /* @type {bool} Prepaid products have a charge frequency greater than shipping frequency. */
                isPrepaid: (subscription?.charge_frequency || 0) > (subscription?.shipping_frequency || 0),
                /* @type {bool} Subscription products have a shipping frequency. */
                isSubscription: !!subscription?.shipping_frequency,
            };
        };
        Object.values(this.cartData?.lineItems ?? {}).forEach((lineItems) => {
            lineItems.forEach((singleLineItem) => {
                const lineSubscriptionData =
                    this.subscriptionData.find((item) => item.line_item === singleLineItem.id) || {};
                const lineRcaData = this.rcaProductData?.find((item) => item.id === singleLineItem.productId) || {};
                singleLineItem.subscription = lineItemSubscriptionData(lineSubscriptionData);

                singleLineItem.rcaData = lineRcaData;
                singleLineItem.variantData =
                    lineRcaData.variants?.find((variant) => variant.id === singleLineItem.variantId) || {};
                items.push(singleLineItem);
            });
        });
        return items;
    }

    /**
     * Indicates whether the current BigCommerce Cart has subscription items in it or not.
     *
     * @readonly
     * @returns {boolean}
     */
    get hasSubscription() {
        return (
            Array.isArray(this.allLineItems) &&
            this.allLineItems.some((lineItem) => {
                /**
                 * We identify whether the bigcommerce cart has a subscription item in it via
                 * subscription data for interfaces and the cdn otherwise.
                 */
                if (lineItem?.subscription?.isSubscription) {
                    return true;
                }
                return (
                    Array.isArray(lineItem.options) &&
                    lineItem.options.some((modifierOption) =>
                        this.rcaProductData.find(
                            (product) => modifierOption.nameId === product.subscriptionModifier?.id
                        )
                    )
                );
            })
        );
    }

    /**
     * @readonly
     * @returns {boolean} Indicates if the cart should be sent to ReCharge or not.
     */
    get isRechargeCart() {
        const result = this.allCheckoutsOnRecharge || this.hasSubscription;
        this.$logger.debug("isReChargeCart", {
            isRechargeCart: result,
            allCheckoutsOnRecharge: this.allCheckoutsOnRecharge,
            hasSubscription: this.hasSubscription,
        });
        return result;
    }

    /**
     * Calls the BigCommerce Storefront Cart API and sets the Cart ID.
     *
     * @returns {Promise<BigcommerceCart>}
     */
    async getCartIdFromStorefrontApi() {
        try {
            const request = this.client.request({
                url: "/api/storefront/carts",
                method: "get",
            });
            const response = await request.send();
            this.cartId = response?.[0]?.id;
        } catch (e) {
            if (e.response?.status === 404) {
                this.$logger.warn(`BigCommerce storefront cart could not be called: ${e}`);
                this.cartId = null;
            }
        }
    }

    /**
     * Calls the BigCommerce Storefront Checkout API and sets the Checkout & Cart Data. This is an
     * enhanced version of the Storefront Cart API call.
     *
     * @param {boolean} [includeModifiers=true] - Should modifier data be included with the cart?
     * @param {boolean} [includePromotions=true] - Should promotions data be included with the cart?
     * @param {boolean} [includeCategoryNames=false] - Should category names be included with the cart?
     * @param {boolean} [includeCustomer=false] - Should customer data be included with the cart?
     * @param {boolean} [includeCustomerGroup=false] - Should the customer group be included with the cart?
     * @param {boolean} [includePayments=false] - Should payments data be included with the cart?
     * @param {boolean} [includeShippingOptions=false] - Should shipping options be included with the cart?
     * @returns {Promise<BigcommerceCart>}
     */
    async getCheckoutData(
        includeModifiers = true,
        includePromotions = true,
        includeCategoryNames = false,
        includeCustomer = false,
        includeCustomerGroup = true,
        includePayments = false,
        includeShippingOptions = false
    ) {
        if (!this.cartId) {
            await this.getCartIdFromStorefrontApi();
        }
        if (this.cartId) {
            const request = this.client.request({
                url: `/api/storefront/checkout/${this.cartId}`,
                method: "get",
            });
            const includeMap = [
                {
                    isIncluded: includeModifiers,
                    value: "cart.lineItems.digitalItems.options,cart.lineItems.physicalItems.options",
                },
                { isIncluded: includePromotions, value: "promotions" },
                {
                    isIncluded: includeCategoryNames,
                    value: "cart.lineItems.digitalItems.categoryNames,cart.lineItems.physicalItems.categoryNames",
                },
                { isIncluded: includeCustomer, value: "customer" },
                { isIncluded: includeCustomerGroup, value: "customer.customerGroup" },
                { isIncluded: includePayments, value: "payments" },
                {
                    isIncluded: includeShippingOptions,
                    value: "consignments.availableShippingOptions",
                },
            ];
            const toInclude = includeMap.filter((i) => i.isIncluded);
            if (includeModifiers) {
                request.params = {
                    include: toInclude.map((item) => item.value).join(","),
                };
            }
            try {
                if (!checkoutQueryPromise) {
                    checkoutQueryPromise = request.send();
                }
                this.checkoutData = await checkoutQueryPromise;
                checkoutQueryPromise = null;
            } catch (e) {
                this.$logger.warn(`BigCommerce checkout cart could not be called: ${e}`);
            }
        }
        return this;
    }

    /**
     * @typedef {object} Amount
     * @property {number} amount
     * @property {integer} integerAmount
     *
     * @typedef {object} BigcommerceInternalOrder
     * @property {string} callbackUrl
     * @property {object} coupon
     * @property {string} currency
     * @property {boolean} customerCanBeCreated
     * @property {boolean} customerCreated
     * @property {Amount} discount
     * @property {Array} discountNotifications
     * @property {object} giftCertificate
     * @property {Amount} grandTotal
     * @property {Amount} handling
     * @property {boolean} hasDigitalItems
     * @property {integer} id
     * @property {boolean} isComplete
     * @property {boolean} isDownloadable
     * @property {Array<object>} items
     * @property {Amount} handling
     * @property {boolean} hasDigitalItems
     * @property {number} id
     * @property {boolean} isComplete
     * @property {boolean} isDownloadable
     * @property {object[]} items
     * @property {number} items.amount
     * @property {number} items.amountAfterDiscount
     * @property {object[]} items.attributes
     * @property {string} items.attributes.name
     * @property {string} items.attributes.product_attribute_id
     * @property {string} items.attributes.validated_value
     * @property {string} items.attributes.value
     * @property {number} items.discount
     * @property {number} items.id
     * @property {string} items.imageUrl
     * @property {number} items.integerAmount
     * @property {number} items.integerAmountAfterDiscount
     * @property {number} items.integerDiscount
     * @property {number} items.integerTax
     * @property {string} items.name
     * @property {number} items.quantity
     * @property {number} items.tax
     * @property {string} items.type
     * @property {number} orderId
     * @property {object} payment
     * @property {null} payment.gateway
     * @property {null} payment.helpText
     * @property {string} payment.id
     * @property {string} payment.status
     * @property {object} shipping
     * @property {number} shipping.amount
     * @property {number} shipping.amountBeforeDiscount
     * @property {number} shipping.integerAmount
     * @property {number} shipping.integerAmountBeforeDiscount
     * @property {boolean} shipping.required
     * @property {object} socialData
     * @property {object} socialData.143
     * @property {object} socialData.143.fb
     * @property {string} socialData.143.fb.channelCode
     * @property {string} socialData.143.fb.channelName
     * @property {string} socialData.143.fb.description
     * @property {string} socialData.143.fb.image
     * @property {string} socialData.143.fb.name
     * @property {string} socialData.143.fb.shareText
     * @property {string} socialData.143.fb.sharingLink
     * @property {string} socialData.143.fb.url
     * @property {object} socialData.143.tw
     * @property {string} socialData.143.tw.channelCode
     * @property {string} socialData.143.tw.channelName
     * @property {string} socialData.143.tw.description
     * @property {string} socialData.143.tw.image
     * @property {string} socialData.143.tw.name
     * @property {string} socialData.143.tw.shareText
     * @property {string} socialData.143.tw.sharingLink
     * @property {string} socialData.143.tw.url
     * @property {string} status
     * @property {object} storeCredit
     * @property {number} storeCredit.amount
     * @property {Amount} subtotal
     * @property {Amount} taxSubtotal
     * @property {Amount} taxTotal
     * @property {Array<Amount>} taxes
     * @property {string} token
     *
     */

    /**
     * @returns {Promise<BigcommerceInternalOrder>} The newly created order.
     */
    async convertToOrder() {
        if (!this.cartId) {
            await this.getCartIdFromStorefrontApi();
        }
        let orderData = {
            customerMessage: "",
            useStoreCredit: false,
        };
        const BCData = window.BCData ?? {};
        if ($("input[name=useStoreCredit]").is(":checked")) {
            orderData.useStoreCredit = true;
        }
        const request = this.client.request({
            url: `/internalapi/v1/checkout/order`,
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "x-xsrf-token": BCData.csrf_token,
            },
            data: orderData,
        });
        const response = await request.send();
        return response?.data?.order;
    }
}
