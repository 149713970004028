import $ from "@/core/libs/jquery";

const ROOT_ORIGIN = window.document;
const EXCLUDED_ATTRIBUTES = ["class", "style", "lang", "id"];
const EXCLUDED_ATTRIBUTE_TAGS = ["html", "body", "main"];

const getTag = (el) => el.get(0)?.tagName?.toLowerCase?.() ?? false;

/**
 * @returns {HTMLElement} Selector - Found HTMLElement.
 */

const getElementSelector = (
    target,
    {
        withClasses = true,
        withAttributes = true,
        excludeClasses = () => false,
        excludeAttributes = () => false,
    } = {}
) => {
    let selector = target.attr("id")
        ? `${getTag(target)}#${target.attr("id")}`
        : `${getTag(target)}`;
    if (withClasses) {
        target.get(0)?.classList?.forEach?.((_class) => {
            if (!excludeClasses(_class)) {
                selector += `.${_class}`;
            }
        });
    }
    if (withAttributes && !EXCLUDED_ATTRIBUTE_TAGS.includes(getTag(target))) {
        const attributes = Array.from(target.get(0)?.attributes);
        attributes.forEach((attr) => {
            if (
                !EXCLUDED_ATTRIBUTES.includes(attr.name) &&
                !excludeAttributes(attr.name, attr.value)
            ) {
                selector += `[${attr.name}="${attr.value}"]`;
            }
        });
    }

    return selector;
};

/**
 *
 * @param {HTMLElement} origin - HTML element that is the origin of the path.
 * @param {HTMLElement} target - HTML element being selected.
 * @param  param.withClasses - List of classes.
 * @param param.withAttributes - List of attributes.
 * @param  param.excludeClasses - Function that will return boolean when class is excluded.
 * @param param.excludeAttributes - Function that will return boolean for attributes with certai value.
 */

export const getPathSelector = (
    origin,
    target,
    {
        withClasses = true,
        withAttributes = true,
        excludeClasses = () => false,
        excludeAttributes = () => false,
    } = {}
) => {
    if (!target) {
        target = origin;
        origin = ROOT_ORIGIN;
    }
    target = $(origin).find(target);
    const options = {
        withClasses,
        withAttributes,
        excludeClasses,
        excludeAttributes,
    };
    let parent = target.parent();
    let selector = [];
    while (parent.length && !parent.is(origin)) {
        selector.push(getElementSelector(parent, options));
        parent = parent.parent();
    }
    selector = selector.reverse();
    const index = $(selector.join(" ")).find(getElementSelector(target, options)).index(target);
    selector.push(`${getElementSelector(target, options)}:eq(${index})`);
    return selector.join(" ");
};
