/**
 *
 */
export class WaitForBase {
    _timeoutPromise = null;
    _timeoutId = 0;

    /**
     *
     * @param msToWait
     * @returns {Promise}
     */
    newTimeoutPromise(msToWait) {
        if (this._timeoutPromise) {
            this.stop();
        }
        this._timeoutPromise = new Promise((r) => {
            this._timeoutId = setTimeout(() => {
                r("timeout");
            }, msToWait);
        });
        return this._timeoutPromise;
    }

    /**
     * @abstract
     */
    start() {
        throw new Error("Method `start()` must be implemented.");
    }

    /**
     *
     */
    stop() {
        clearTimeout(this._timeoutId);
        Promise.resolve(this._timeoutPromise);
        this._timeoutId = 0;
        this._timeoutPromise = null;
    }

    /**
     * Stop the currently running timeOut function and start it again. This resets the countdown timer for the callback.
     * @returns {Promise}
     */
    restart() {
        this.stop();
        return this.start();
    }

    /**
     *
     * @param reason
     */
    throwTimeoutError(reason) {
        const error = new Error(reason);
        error.isTimeout = true;
        throw error;
    }
}
